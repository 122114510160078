<template>
    <PillMenuBar v-if="!isMobile() && menuItems" :navigationItems="menuItems" class='portfolio-menu' header-type="main">
        <template #custom>
            <Button v-if="isCollaborativePortfolio || isCollaborativeAccount" v-tooltip.left="'Create Motion'"
                class='green-button__secondary create-motion-button' @click="onCreateMotionClick">
                <img class="motions-icon" src="/assets/images/motion_icon2.png">
            </Button>
        </template>
    </PillMenuBar>

    <div id='portfolioOverviewContainer' v-if="selectedAccountData">
        <GroupMotions v-if="isCollaborativePortfolio && isMenuItemSelected(menuItemsLabels.motions)" 
            :selectedAccountData="selectedAccountData" :isPortfolioManager="isGroupOwnerOrAdmin"/>
        <Overview v-else :selectedAccountData="selectedAccountData" :fullLoad="true" :selectedGroup="selectedGroup" />
    </div>

</template>

<script>
import Overview from '../portfolio/Overview.vue';
import GroupMotions from './GroupMotions.vue';
import PillMenuBar from '../menu/PillMenuBar.vue';

import BrowserUtils from '../../utilities/BrowserUtils';
import PortfolioService from '../../service/PortfolioService';
import UserUtils from '../../utilities/UserUtils';

import EventBus from '../../event-bus';
import { mapState } from 'vuex';
import { COLLABORATIVE_PORTFOLIO_TYPE } from '../../common/constants';

const menuItemsLabels = {
    overview: 'Overview',
    motions: 'Motions'
};

export default {
    name: 'GroupPortfolio',
    components: {
        Overview,
        GroupMotions,
        PillMenuBar
    },

    data() {
        return {
            menuItemsLabels,
            menuItemsAggregate: [
                {label: menuItemsLabels.overview, action: ()=>this.selectMenuItem(menuItemsLabels.overview), selected: true},
			],
            menuItemsCollaborative: [
                {label: menuItemsLabels.overview, action: ()=>this.selectMenuItem(menuItemsLabels.overview), selected: true},
                {label: menuItemsLabels.motions, action: ()=>this.selectMenuItem(menuItemsLabels.motions), selected: false, materialIcon: "campaign"},
			],
            menuItems: this.menuItemsAggregate,

            selectedMenuItemsLabel: menuItemsLabels.overview,
            
            selectedAccountData: null,
        };
    },

    computed: {
        ...mapState(['selectedGroup','selectedGroupConduit','currentlySelectedPortfolio']),

        isCollaborativePortfolio(){
            return this.currentlySelectedPortfolio?.groupPortfolioTypeId === COLLABORATIVE_PORTFOLIO_TYPE.ID;
        },

        isCollaborativeAccount(){
            return this.selectedAccountData?.collaborative;
        },

        isGroupOwnerOrAdmin() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId) || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },
    },

    watch: {
        selectedGroupConduit(newConduit, oldConduit) { // eslint-disable-line

            this.selectedAccountData = newConduit.obj;

            if (this.selectedAccountData.accountTypeId !== -1) {
                this.fetchAccountBalance();
            }

            this.initMenu();
        },
    },

    created() {
        EventBus.off('reload-account-from-groups');
        EventBus.on('reload-account-from-groups', this.reloadAccount);

        this.initMenu();
    },
    
    mounted() {
        if (this.selectedGroupConduit) {
            this.selectedAccountData = this.selectedGroupConduit.obj;
        }
    },

    methods: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        initMenu() {
            if(this.isCollaborativePortfolio){
                this.menuItems = this.menuItemsCollaborative;
            } else {
                this.menuItems = this.menuItemsAggregate;
            }

            this.selectMenuItem(this.selectedGroupConduit.tab);
        },

        isMenuItemSelected(label) {
            return this.selectedMenuItemsLabel === label;
        },

        selectMenuItem(label) {
            this.menuItems.forEach(item => item.selected = item.label === label);
            this.selectedMenuItemsLabel = label;

            let currentGroupConduit = this.selectedGroupConduit;
            currentGroupConduit.tab = label;

            this.$store.commit('SET_SELECTED_GROUP_CONDUIT', currentGroupConduit);
        },

        onCreateMotionClick() {
            EventBus.emit('open-create-motion-modal', {group: this.selectedGroup, accountId: this.selectedAccountData?.investmentAccountId});
        },

        reloadAccount() {
            if (this.selectedGroupConduit.type === 'portfolio') { // Ensure we don't refresh while we are selected on a feed or text channel
                if (this.selectedAccountData.investmentAccountId !== undefined) {
                    this.fetchAccountBalance();
                } else { // If selected portfolio is a group type
                    EventBus.emit('refresh-portfolio-overview');
                }
            }
        },

        fetchAccountBalance() {
            if (this.selectedAccountData.investmentAccountId) {
                PortfolioService.getGroupPortfolioInvestmentAccount(this.selectedAccountData.groupPortfolioId, this.selectedAccountData.investmentAccountId).then(resp => {
                    if( resp.data.status == 'success') {
                        // console.log("fetchAccountBalance", this.selectedAccountData, resp.data);
                        this.selectedAccountData.totalValue = resp.data.account.totalValue;
                        this.selectedAccountData.cashPositions = resp.data.account.cashPositions;
                        this.selectedAccountData.totalBorrowed = resp.data.account.totalBorrowed;
                        this.selectedAccountData.currentBuyingPower = resp.data.account.currentBuyingPower;
                        this.selectedAccountData['collaborative'] = resp.data.account.collaborative;
                    }
                });
            }
        }
    },
};
</script>

<style>

</style>

<style scoped>
::v-deep(*:not(.pi, .material-icons-outlined, .material-symbols-outlined)),
::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.tab-content),
::v-deep(.p-grid) {
    margin: 0 !important;
    padding: 0 !important;
}

.tab-div {
    position: relative;
    margin: 0 16px;
}

.portfolio-menu {
    padding: 0px 0px 16px !important;
    margin-right: 8px;
}

::v-deep(.create-motion-button) {
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-radius: 28px;
}

.motions-icon {
    width: 20px !important;
}

@media (max-width: 760px) {
    .tab-div {
        padding-left: 5px;
        padding-right: 5px;
    }
}
</style>
