import APIService from '@/common/APIService';

class TradingServiceImpl {
    getOrders(investmentAccountId, limit, offset, pendingOnly) {
        return APIService.get(`trade/orders?investmentAccountId=${investmentAccountId}&limit=${limit}&offset=${offset}&pendingOnly=${pendingOnly}`);
    }

    placeOrder(req) {
        return APIService.post(`trade/order`, req);
    }

    recordTradeOrder(req) {
        return APIService.post(`trade/order/record`, req);
    }

    cancelOrder(tradeOrderId) {
        return APIService.post(`trade/order/cancel?tradeOrderId=${tradeOrderId}`, {});
    }

    estimateOrder(req) {
        return APIService.post('trade/estimate', req);
    }
}

const TradingService = new TradingServiceImpl();

export default TradingService;