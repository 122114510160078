<template>
    <div class="card">
        <DataTable id='motionsTable' :key="motionsTableKey" :value="motions" :loading='showLoading' :totalRecords="totalMotionsRecords"
            :rowClass='motionsRowClass' :resizableColumns="true" columnResizeMode="expand" :paginator="true"
            :rows="limitPerPage" :lazy="true" @page="onPage">

            <Column field="title" header="Summary" headerClass="motions-header" class="motions-column" />
            <Column header="Motion Status" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    <span v-tooltip.bottom="getMotionStatusDetailsText(slotProps.data)">{{
                        getMotionStatusText(slotProps.data) }}</span>
                </template>
            </Column>
            <Column v-if="userVotesPage" header="My Vote" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    <span v-tooltip.bottom='getMyVoteDateText(slotProps.data)'>
                        {{ getMyVoteText(slotProps.data) }}
                    </span>
                </template>
            </Column>
            <Column header="Quorum" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ getQuorumStatusText(slotProps.data) }}
                </template>
            </Column>
            <Column header="Status Date" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ getStatusDateText(slotProps.data) }}
                </template>
            </Column>
            <Column header="Deadline" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ getDeadlineDateText(slotProps.data) }}
                </template>
            </Column>
            <Column v-if="!userMotionsPage" field="submittedBy" header="User" headerClass="motions-header" class="motions-column" />
            <Column v-if="userMotionsPage" field="groupName" header="Group" headerClass="motions-header" class="motions-column" />
            <Column v-if="userMotionsPage" field="portfolioName" header="Portfolio" headerClass="motions-header" class="motions-column" />
            <Column field="accountName" header="Account" headerClass="motions-header" class="motions-column" />
            <Column field="motionTradeOrders" header="Order Status" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ getOrderStatusText(slotProps.data) }}
                </template>
            </Column>
            <Column header="Actions" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    <Button class='p-button-icon-only action-button-blue blue-button' icon="pi pi-eye"
                        v-tooltip.bottom="'View'" @click='openViewMotionModal(slotProps.data)' />
                    <Button v-if="showEditButton(slotProps.data)" class='p-button-icon-only action-button-blue blue-button' icon="pi pi-pencil"
                        v-tooltip.bottom="'Edit'" @click='openEditMotionModal(slotProps.data)' />
                    <Button v-if="showExecuteButton(slotProps.data)" class='p-button-icon-only action-button-blue blue-button'
                        v-tooltip.bottom="'Execute'" @click='openExecuteMotionModal(slotProps.data)'>
                        <span class="material-symbols-outlined">currency_exchange</span>
                    </Button>
                    <Button v-if="showWithdrawButton(slotProps.data)" class='p-button-icon-only action-button-blue blue-button' icon="pi pi-undo"
                        v-tooltip.bottom="'Withdraw'" @click='openWithdrawMotionModal(slotProps.data)' />
                    <Button v-if="showVetoButton(slotProps.data)" class='p-button-icon-only action-button-blue blue-button'
                        icon="pi pi-times" v-tooltip.bottom="'Veto'" @click='openVetoMotionModal(slotProps.data)' />
                    <Button v-if="showDeleteButton(slotProps.data)" class='p-button-icon-only action-button-blue blue-button'
                        v-tooltip.bottom="'Delete'" @click='openDeleteMotionModal(slotProps.data)'>
                        <span class="material-symbols-outlined">delete_outline</span>
                    </Button>
                </template>
            </Column>
            <template #empty v-if='!showLoading'>
                <div style="text-align: center;">
                    <p>{{getNoDataText(0)}}</p>
                    <p v-if="getNoDataText(1)">{{getNoDataText(1)}}</p>
                    <div v-if="userMotionsPage" style="display: flex; justify-content: center; align-items: center;">
                        <span>{{getNoDataText(2)}}</span>
                        <div class="example-create-motion-button" v-tooltip.bottom="'In the top right corner'">
                            <span class="material-symbols-outlined motions-icon">campaign</span>
                        </div>
                        <span>{{getNoDataText(3)}}</span>
                    </div>
                    <div v-else-if="userVotesPage" style="display: flex; justify-content: center;">
                        <span>{{getNoDataText(2)}}</span>
                        <router-link style="margin: 0px 3px; color: #33CC99;;" :to="'/home'">
                            {{"Home Screen"}}
                        </router-link>
                        <span>{{getNoDataText(3)}}</span>
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
    <ConfirmModal headerText="Execute Motion?" ref="executeMotionModal" @confirmed="onExecuteMotionConfirmed" confirmButtonGreen/>
    <ConfirmModal headerText="Withdraw Motion?" ref="withdrawMotionModal" @confirmed="onWithdrawMotionConfirmed" confirmButtonGreen/>
    <ConfirmModal headerText="Delete Motion?" ref="deleteMotionModal" @confirmed="onDeleteMotionConfirmed"/>
    <VetoMotionModal ref="vetoMotionModal"/>
    <ViewMotionModal ref="viewMotionModal"/>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ConfirmModal from '../modal/ConfirmModal.vue';
import VetoMotionModal from './modal/VetoMotionModal.vue';
import ViewMotionModal from './modal/ViewMotionModal.vue';

import MotionService from '../../service/MotionService';
import { MOTION_STATUS, MOTION_STATUS_ID } from '../../common/constants';
import TimeUtils from '../../utilities/TimeUtils';
import UserUtils from '../../utilities/UserUtils';
import EventBus from '../../event-bus';

const noDataTextOptions = {
    votes: [
        "This is where you will see all the trade motions that you have voted on.",
        "You have not yet voted on any motions.",
        "Go to the",
        "to find motions to vote on."
    ],
    motions: [
        "This is where any motions you have created will be listed.",
        "You have not created any motions yet.",
        "Click the create motion button",
        "to create a motion to invest."
    ],
    groupMotions: [
        "No motions submitted.",
    ]
};

export default {
    name: 'GroupMotions',
    components: {
        DataTable,
        Column,
        ConfirmModal,
        VetoMotionModal,
        ViewMotionModal
    },
    props: {
        selectedAccountData: {
            type: Object,
            required: false,
        },
        isPortfolioManager: {
            type: Boolean,
            required: false,
            default: false
        },
        userMotionsPage: {
            type: Boolean,
            required: false,
            default: false
        },
        userVotesPage: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            noDataTextOptions,

            showLoading: false,
            motionsTableKey: 0,
            currentOffset: 0,

            motions: [],
            totalMotionsRecords: 0,

            confirmModalText: '',
            currentMotion: '',
        };
    },

    computed:{
        limitPerPage(){
            return this.userMotionsPage ? 8 : 6;
        }
    },

    mounted() {
        this.init();
    },

    activated() {
        this.init();
    },

    methods: {
        init(){
            EventBus.off('refresh-motions');
            EventBus.on('refresh-motions', this.refreshMotionsData);

            this.motionsTableKey += 1;
            this.showLoading = true;
            this.currentOffset = 0;
            this.refreshMotionsData();
        },
        
        showDeleteButton(motion){
            return UserUtils.isUser(motion.userId)
                && motion.statusId == MOTION_STATUS_ID.draft;
        },

        showEditButton(motion){
            return UserUtils.isUser(motion.userId)
                && motion.statusId == MOTION_STATUS_ID.draft;
        },

        showExecuteButton(motion){
            if( this.isPortfolioManager ) {
                if(  !motion.automaticExec ) {
                    if(motion.statusId == MOTION_STATUS_ID.passed &&  !this.isMotionTradeOrderExecuted(motion) && !this.isMotionTradeOrderScheduledForExecution(motion)){
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    // auto execute but trade order failed e.g nsf.  give them a chance to do it manually then
                    if(motion.statusId == MOTION_STATUS_ID.passed &&  this.isMotionTradeOrderAborted(motion) ){
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
                
        },

        showVetoButton(motion){
            return this.isPortfolioManager
                && !this.isMotionTradeOrderExecuted(motion)
                && !this.isMotionTradeOrderScheduledForExecution(motion)
                && motion.statusId != MOTION_STATUS_ID.withdrawn
                && motion.statusId != MOTION_STATUS_ID.vetoed;
        },
        
        showWithdrawButton(motion){
            return UserUtils.isUser(motion.userId)
                && motion.statusId == MOTION_STATUS_ID.submitted;
        },

        openVetoMotionModal(motion) {
            this.$refs.vetoMotionModal.open(motion);
        },

        openViewMotionModal(motion) {
            this.$refs.viewMotionModal.open(motion);
        },

        openEditMotionModal(motion) {
            EventBus.emit('open-create-motion-modal', { draftMotion: motion, userMotionsPage: this.userMotionsPage });
        },

        motionsRowClass() {
            return 'motions-row';
        },

        getNoDataText(n) {
            if(this.userVotesPage){
                return noDataTextOptions.votes[n];
            } else if(this.userMotionsPage) {
                return noDataTextOptions.motions[n];
            } else {
                return noDataTextOptions.groupMotions[n];
            }
        },

        getGroupAndAccountNamesText(motion) {
            return motion.groupName + ' / ' + motion.accountName;
        },

        getMyVoteText(motion) {
            return motion.ballot.vote ? "Yes" : "No";
        },

        getMyVoteDateText(motion) {
            const date = new Date(motion.ballot.createdDate);
            return 'Date: ' + TimeUtils.getFormattedTime(date, 'YYYY/MM/DD HH:mm');
        },

        getMotionStatusText(motion) {
            const name = MOTION_STATUS[motion.statusId];
            if (motion.statusId == MOTION_STATUS_ID.vetoed) {
                return name;
            } else {
                if (motion.yesVote + motion.noVote < 3) {
                    return `${name}`;
                } else if (motion.yesVote >= motion.noVote) {
                    const yesPercentage = Math.round((motion.yesVote / (motion.yesVote + motion.noVote)) * 100);
                    return `${name} (${yesPercentage}% Yes)`;
                } else {
                    const noPercentage = Math.round((motion.noVote / (motion.noVote + motion.yesVote)) * 100);
                    return `${name} (${noPercentage}% No)`;
                }
            }
        },

        getMotionStatusDetailsText(motion) {
            if (motion.vetoReason || motion.statusId == MOTION_STATUS_ID.vetoed) {
                return motion.vetoReason;
            } else if (motion.withdrawalNote) {
                return motion.withdrawalNote;
            } else {
                return undefined;
            }
        },

        getQuorumStatusText(motion) {
            return motion.yesVote + motion.noVote >= motion.quorum ? "Yes" : "No";
        },

        getDeadlineDateText(motion) {
            const date = new Date(motion.deadlineDate);
            return TimeUtils.getFormattedTime(date, 'YYYY/MM/DD HH:mm');
        },

        getStatusDateText(motion){
            let date;
            if(motion.tallyDate) {
                date = new Date(motion.tallyDate);
            } else if(motion.withdrawalDate) {
                date = new Date(motion.withdrawalDate);
            } else if(motion.submittedDate) {
                date = new Date(motion.submittedDate);
            } 
            return TimeUtils.getFormattedTime(date, 'YYYY/MM/DD HH:mm');
        },

        isMotionTradeOrderExecuted(motion){
            return motion.motionTradeOrders[0]['executedDate'] != null && motion.motionTradeOrders[0]['abortedDate'] == null;
        },

         isMotionTradeOrderScheduledForExecution(motion){
            
            return motion.motionTradeOrders[0]['executedDate'] != null && motion.motionTradeOrders[0]['abortedDate'] == null && 
                    motion.motionTradeOrders[0].tradeOrder.executionDate == null;
            
        },

        isMotionTradeOrderAborted(motion) {
             return motion.motionTradeOrders[0]['abortedDate'] != null;
        },

        getOrderStatusText(motion) {
            if( motion.statusId == MOTION_STATUS_ID.passed ) {
                if( this.isMotionTradeOrderScheduledForExecution(motion)) {
                    return "Scheduled";
                }
                else if(this.isMotionTradeOrderExecuted(motion) ) {
                    return "Executed";
                }
                else if( this.isMotionTradeOrderAborted(motion)) {
                    return "Aborted("+motion.motionTradeOrders[0]['reasonAborted']+")";
                }
                else {
                    return "-";
                }
            }
            else {
                return "-";
            }
        },

        onPage(event) {
            this.currentOffset = event.first;
            this.refreshMotionsData();
        },

        refreshMotionsData() {
            if (this.userVotesPage) {
                MotionService.getUserVotes(this.currentOffset, this.limitPerPage).then(resp => {
                    this.showLoading = false;

                    if (resp.data.status == "success") {
                        const motionsResp = resp.data.motions;
                        this.motions = motionsResp.data;
                        this.totalMotionsRecords = motionsResp.totalCount;

                        console.log("GroupMotions getUserVotes", resp.data, this.totalMotionsRecords);
                    } else {
                        console.error("error getting motions:" + resp.data.message)
                    }
                });
            } else if (this.userMotionsPage) {
                MotionService.getUserMotions(this.currentOffset, this.limitPerPage).then(resp => {
                    this.showLoading = false;

                    if (resp.data.status == "success") {
                        const motionsResp = resp.data.motions;
                        this.motions = motionsResp.data;
                        this.totalMotionsRecords = motionsResp.totalCount;

                        console.log("GroupMotions getUserMotions", resp.data, this.totalMotionsRecords);
                    } else {
                        console.error("error getting motions:" + resp.data.message)
                    }
                });
            } else {
                MotionService.getMotions(this.selectedAccountData.groupPortfolioId, this.currentOffset, this.limitPerPage).then(resp => {
                    this.showLoading = false;

                    if (resp.data.status == "success") {
                        const motionsResp = resp.data.motions;
                        this.motions = motionsResp.data;
                        this.totalMotionsRecords = motionsResp.totalCount;

                        console.log("GroupMotions getMotions", resp.data, this.totalMotionsRecords);
                    } else {
                        console.error("error getting account motions:" + resp.data.message)
                    }
                });
            }
        },

        openExecuteMotionModal(motion) {
            this.currentMotion = motion;
            this.$refs.executeMotionModal.open();
        },

        onExecuteMotionConfirmed(){
            if(this.currentMotion){
                MotionService.executeMotion(this.currentMotion.motionId).then(resp => {
                    if (resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: "Motion Scheduled for Execution.", detail: '', life: 3000, group: 'tc' });
                        this.refreshMotionsData();
                    } else {
                        console.error("error execute motion:" + resp.data.message)
                    }
                });
            }
        },

        openWithdrawMotionModal(motion) {
            this.currentMotion = motion;
            this.$refs.withdrawMotionModal.open();
        },

        onWithdrawMotionConfirmed(){
            if(this.currentMotion){
                MotionService.withdrawMotion(this.currentMotion.motionId).then(resp => {
                    if (resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: "Motion Withdrawn.", detail: '', life: 3000, group: 'tc' });
                        this.refreshMotionsData();
                    } else {
                        console.error("error withdraw motion:" + resp.data.message)
                    }
                });
            }
        },

        openDeleteMotionModal(motion) {
            this.currentMotion = motion;
            this.$refs.deleteMotionModal.open();
        },

        onDeleteMotionConfirmed(){
            if(this.currentMotion){
                MotionService.deleteMotion(this.currentMotion.motionId).then(resp => {
                    if (resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: "Motion Deleted.", detail: '', life: 3000, group: 'tc' });
                        this.refreshMotionsData();
                    } else {
                        console.error("error delete motion:" + resp.data.message)
                    }
                });
            }
        },

    }

};
</script>

<style scoped>
::v-deep(.motions-header), 
::v-deep(.p-column-title) {
  font-family: 'Trebuchet MS';
  font-weight: bold;
  font-size: 14px;
  background: #fff !important;
  color: #32364e;
}

.action-button-blue {
    border-radius: 16px;
    padding: 6px 16px;
    border-style: solid;
    border-width: 1px;
    margin-right: 4px;
    overflow: visible;
}

.action-button-blue:last-child {
    margin-right: 0px;
}

.action-button-blue:active,
.action-button-blue:hover {
    background: #32364e;
    border-color: #32364e !important;
    color: #FFFFFF;
}

.action-button-blue .material-symbols-outlined {
    font-size: 16px !important;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
    color: #32364e;
}
::v-deep(.p-paginator-first:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-next:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-last:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover) {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
::v-deep(.p-paginator-first:focus),
::v-deep(.p-paginator-prev:focus),
::v-deep(.p-paginator-next:focus),
::v-deep(.p-paginator-last:focus),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus) {
    box-shadow: none;
}

.example-create-motion-button {
    padding: 5px;
    border: 1px solid #33CC99 !important;
    border-style: solid;
    border-radius: 28px;
    width: 32px !important;
    height: 32px !important;
    margin: 0px 4px;
}
.example-create-motion-button .material-symbols-outlined {
    font-size: 20px !important;
}
</style>
