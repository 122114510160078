<template>
    <template>
        <Dialog :header='headerLabel' v-model:visible="display" :modal="true" :draggable="false" class='position-input-modal' @hide="onHide">
            <div class='header-content' v-if='account'>
                <span>Account: <b>{{account.name || account.accountName}}</b></span>
                <span>Currency: <b>{{currencyUtils.getCurrencyNameViaId(account.currencyId)}}</b></span>
            </div>
            
            <div class='form-section'>
                <div class='input-section' v-if="editMode">
                    <div class="p-float-label">
                        <Dropdown id="actionTypeDropdown" class="action-type-dropdown dropdown" v-model="actionTypeOption" optionLabel="name" :options="actionTypeOptions" />
                        <label for="actionTypeDropdown" class='input-label'>Buy/Sell</label>
                    </div>
                </div>

                <!-- Security search bar will only appear if we're creating an order from the actual orders page from Portfolios -->
                <div class='input-section'>
                    <span class="p-float-label p-input-icon-left security-search-bar-container" v-click-away="onSecuritySearchClickAway">
                        <AutoComplete id="securitySearchBarForPositions" ref='securitySearchBar' placeholder="Select a security" v-model="selectedSecurity" 
                            :suggestions="securityList" @complete="searchSecurities($event)" field="symbol" optionGroupLabel="label" optionGroupChildren="items"
                            panelClass="security-search-record-position-panel" @item-select="onSecuritySelected($event)" :disabled="editMode" >
                            <template #item="slotProps">

                                <span class='symbol'>{{slotProps.item.symbol.replace("/", ".")}}</span><br>
                                <small>
                                    {{(slotProps.item.company ? slotProps.item.company : slotProps.item.name)}}
                                    {{ (slotProps.item.exchangeSymbol ? " - "+securityFormatter.getFormattedExchange(slotProps.item.exchangeSymbol): "")}}
                                </small>
                            </template>
                        </AutoComplete>
                        <i class="pi pi-search" />
                        <label for="securitySearchBarForPositions" class='input-label'>Security</label>
                    </span>

                    <div class='quote-data-section' v-if='selectedSecurity.symbol'>
                        <QuoteFeedTable :askPrice="askPrice" :askSize="askSize" :bidPrice="bidPrice" :bidSize="bidSize"
                        :highValue="highValue" :lowValue="lowValue" :openValue="openValue" :volume="volume" />
                    </div>
                </div>

                <div class='input-section'>
                    <div class="p-float-label">
                        <InputNumber id='quantityInput' class='quantity-input' v-model="quantity"/>
                        <label for="quantityInput" class='input-label'>Quantity</label>
                    </div>
                </div>

                <div class='input-section'>
                    <div class="p-float-label p-input-icon-right">
                        <i class="pi pi-dollar" />
                        <InputNumber id='priceInput' class='avg-cost-input' v-model="avgCost" mode="decimal" :minFractionDigits="2"/>
                        <label for="priceInput" class='input-label'>{{priceLabel}}</label>
                    </div>
                </div>
            </div>

            <template #footer>
                <Button label="Save" class='save-button green-button__primary' @click='positionHandler' :loading="loading" :disabled="disableSaveButton"/>
            </template>
        </Dialog>
        <ConfirmModal headerText="Are you sure the price is accurate?" ref="confirmPriceModal" @confirmed="onPriceConfirmed">
            <template #content>
                <div class="confirm-price-modal-content">
                    <p>The {{isSellTradeType() ? "Sale" : "Purchase"}} price you have entered ({{formatPriceValue(avgCost)}}) is more than 25% {{priceEnteredPercentageDiff > 0 ? "above" : "below"}} the most recent market price ({{formatPriceValue(askPrice)}}).</p>
                    <p>Entering an inaccurate price can affect the validity of returns calculations for the account.</p>
                </div>
            </template>
        </ConfirmModal>
    </template>
</template>

<script>
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import QuoteFeedTable from '../../QuoteMedia/QuoteFeedTable.vue';
import ConfirmModal from '../../modal/ConfirmModal.vue';

import {BUY_TRADE_TYPE, SELL_TRADE_TYPE, ASSET_TYPES} from '../../../common/constants';
import ResearchService from '../../../service/ResearchService';
import SecurityFormatter from '../../../common/SecurityFormatter';
import BrowserUtils from '../../../utilities/BrowserUtils';
import CurrencyUilts from '../../../utilities/CurrencyUtils';
import FormattingUtils from '../../../utilities/FormattingUtils';
import NumberUtils from '../../../utilities/NumberUtils';
import DataService from '../../../service/DataService';
import PortfolioService from '../../../service/PortfolioService';
import EventBus from '../../../event-bus';

const POLLING_PRICE_INTERVAL = 10000;

export default {
    name: 'PositionInputModal',
    emits: ['create-account'],
    components: {
    Dialog,
    Dropdown,
    InputNumber,
    QuoteFeedTable,
    ConfirmModal
},

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        priceLabel() {
            return this.editMode ? 'Price' : 'Avg. Cost Per Unit';
        },

        disableSaveButton() {
            if (this.editMode) {
                return !this.actionTypeOption || !this.quantity || !this.avgCost;
            } else {
                return !this.selectedSecurity?.symbol || !this.quantity || !this.avgCost;
            }
        },

        headerLabel() {
            return this.editMode ? 'Update Position' : 'New Position';
        }
    },
    data() {
        return {
            securityFormatter: SecurityFormatter,
            currencyUtils: CurrencyUilts,
            numberUtils: NumberUtils,
            formatter: new FormattingUtils(),

            display: false,
            loading: false,
            editMode: false,
            account: null,
            refetcher: null,
            fromGroup: false,
            fromUserProfile: false,

            securitySearchBarPlaceholder: 'Add a security',
            securityList: [],
            currentSecurity: null,
            selectedSecurity: {
                symbol: "",
                assetTypeId: null,
                assetId: null,
                exchangeSymbol: null,
                companyName: null
            },

            askPrice: null,
            bidPrice: null,
            openValue: null,
            highValue: null,
            lowValue: null,
            volume: null,
            askSize: null,
            bidSize: null,

            actionTypeOption: null,
            actionTypeOptions: [{id: BUY_TRADE_TYPE['ID'], name: BUY_TRADE_TYPE['NAME']}, {id: SELL_TRADE_TYPE['ID'], name: SELL_TRADE_TYPE['NAME']}],
            actionTypeDropdownPlaceholder: 'Select an Action Type',

            avgCost: null,
            priceEnteredPercentageDiff: null,

            quantity: null,
            quantityPlaceholder: 'Enter in value for quantity',
        }
    },


    methods: {
        open(data) {
            this.account = null;
            this.fromGroup = data.fromGroup;
            this.fromUserProfile = data.fromUserProfile;

            if (data.account) {
                this.account = data.account;
            }

            if (data?.security) {
                this.initSelectedSecurity(data.security);
                 this.startPollingPrices();
            }

            this.quantity = data?.quantity;

            if (!this.fromUserProfile) {
                this.editMode = data.security !== undefined && data.quantity != undefined;
            } else {
                this.editMode = true;
            }
            
            this.display = true;
        },

        onClose() {
            this.display = false;
        },

        openConfirmPriceModal(){
            this.$refs.confirmPriceModal.open();
        },

        onPriceConfirmed(){
            this.positionHandler(true);
        },

        isSellTradeType(){
            return this.editMode && this.actionTypeOption?.id == SELL_TRADE_TYPE['ID'];
        },

        formatPriceValue(value) {
            if( !isNaN(value) ) {
                return '$' + value.toLocaleString('en-US', {
                    minimumFractionDigits: 2, 
                    maximumFractionDigits: 2
                });
            }
        },

        positionHandler(isPriceConfirmed) {
            const currentPrice = this.isSellTradeType() ? this.bidPrice : this.askPrice;

            if(isPriceConfirmed !== true && currentPrice && currentPrice > 0){
                this.priceEnteredPercentageDiff = (this.avgCost / currentPrice) - 1;
                // console.log("positionHandler", this.avgCost, currentPrice, Math.abs(this.enteredPricePercentageDiff);
                if( Math.abs(this.priceEnteredPercentageDiff) > 0.25 ){
                    this.openConfirmPriceModal();
                    return;
                }
            }

            this.loading = true;
            if (this.editMode) {
                PortfolioService.updatePosition(this.account.investmentAccountId, this.avgCost, this.selectedSecurity.assetId, this.selectedSecurity.assetTypeId, this.quantity, this.actionTypeOption.id).then((resp) => {
                    this.loading = false;
                    if (resp.data.status === 'success') {
                        if (!this.fromGroup) {
                            if (!this.fromUserProfile) {
                                EventBus.emit('reload-account');
                            } else {
                                EventBus.emit('refresh-profile-portfolio-overview');
                            }
                        } else {
                            EventBus.emit('reload-account-from-groups');
                        }
                        this.$toast.add({ severity: 'success', summary: 'Successfully updated position!', life: 3000, group: 'center'});
                        this.onClose();
                    } else {
                        console.error('error updating position: ', + resp.data.message);
                        this.$toast.add({ severity: 'error', summary: resp.data.message, life: 3000, group: 'center'});
                    }
                })
                .catch(err => {
                    this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                    console.log("unexpected error updating position: "+err);
                })
            } else {
                PortfolioService.addPosition(this.account.investmentAccountId, this.avgCost, this.selectedSecurity.assetId, this.selectedSecurity.assetTypeId, this.quantity).then((resp) => {
                    this.loading = false;
                    if (resp.data.status === 'success') {
                        EventBus.emit('reload-account');
                        this.$toast.add({ severity: 'success', summary: 'Successfully added position!', life: 3000, group: 'center'});
                        this.onClose();
                    } else {
                        console.error('error adding position: ', + resp.data.message);
                        this.$toast.add({ severity: 'error', summary: resp.data.message, life: 3000, group: 'center'});
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                    console.log("unexpected error adding position: "+err);
                })
            }
        },

        onSecuritySearchClickAway(event) {
            if (this.selectedSecurity.symbol === undefined) {
                if (this.currentSecurity) {
                    this.selectedSecurity = {...this.currentSecurity};

                    this.$nextTick(() => {
                        this.$refs.securitySearchBar.$emit('hide', event);
                    });
                } else {
                    this.resetSelectedSecurity();
                }
            }
        },

        resetSelectedSecurity() {
            this.selectedSecurity = {symbol: "", assetTypeId: null, assetId: null, exchangeSymbol: null, companyName: null};
            this.currentSecurity = null;
        },

        initSelectedSecurity(security) {
            const isSecurity = security.securityId !== undefined;

            this.selectedSecurity.symbol = security.symbol;
            this.selectedSecurity.assetTypeId = isSecurity ? ASSET_TYPES['EQUITY'] : ASSET_TYPES['FUND'];
            this.selectedSecurity.assetId = isSecurity ? security.securityId : security.fundId;
            this.selectedSecurity.exchangeSymbol = security.exchangeSymbol;
            this.selectedSecurity.companyName = isSecurity ? security.company : security.name;

            this.currentSecurity = {...this.selectedSecurity};
        },

        onHide() {
            this.actionTypeOption = null;
            this.avgCost = null;
            this.quantity = null;
            this.originalData = {
                quantity: null,
                avgCost: null
            };
            this.resetSelectedSecurity();
            this.stopPollingPrices();
        },

        searchSecurities(event){
            ResearchService.queryMarket('All', event.query, 10, 0).then(response => {
				
                let queryResults = response.data;

                this.securityList = [
                {
                    label: 'Equities', code: 'EQ', 
                    items: queryResults.securities
                },
                {
                    label: 'Funds', code: 'F', 
                    items: queryResults.funds
                }];
            })
            .catch(error => {
                this.securityList = []
                
                console.debug('There was an error:', error.response)
            })
        },

        onSecuritySelected(event) {

            this.$refs.securitySearchBar.$el.blur();

            this.initSelectedSecurity(event.value);
            this.startPollingPrices();
		},

        resetQuoteDataValues() {
            this.askPrice = null;
            this.bidPrice = null;
            this.openValue = null;
            this.highValue = null;
            this.lowValue = null;
            this.volume = null;
            this.askSize = null;
            this.bidSize = null;
        },

        startPollingPrices() {
            if (this.refetcher) {
                this.resetQuoteDataValues();
                clearInterval(this.refetcher);
            }
            this.fetchPrices();

            this.refetcher = setInterval(() => {
                this.fetchPrices();
            }, POLLING_PRICE_INTERVAL);
        },

        stopPollingPrices() {
            if (this.refetcher) {
                clearInterval(this.refetcher);
                this.resetQuoteDataValues();
                this.refetcher = null;
            }
        },

        fetchPrices() {
            DataService.getSnapQuotes(SecurityFormatter.getSymbolViaCdnExchanges(this.selectedSecurity)).then((resp) => {
                const quoteData = resp.data.results.quote[0];
                if( quoteData && quoteData.pricedata) {
                    this.askPrice = quoteData.pricedata.ask;
                    this.bidPrice = quoteData.pricedata.bid;
                    this.openValue = quoteData.pricedata.open;
                    this.highValue = quoteData.pricedata.high;
                    this.lowValue = quoteData.pricedata.low;
                    this.volume = quoteData.pricedata.tradevolume;
                    this.askSize = quoteData.pricedata.asksize;
                    this.bidSize = quoteData.pricedata.bidsize;
                }
            });
        },
    },

}
</script>

<style>
.confirm-price-modal-content {
    margin: 0 16px 16px;
}

.position-input-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
}

.position-input-modal .p-dialog-header {
    padding: 16px;
}

.position-input-modal .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: visible;
}

.position-input-modal .p-dialog-footer {
    padding: 16px 0px;
    text-align: center;
}

@media (max-width: 760px) {
    .position-input-modal {
    
        height: 100vh!important;
        
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
        border-radius: 0;
    }

    .position-input-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .position-input-modal .p-dialog-content {
        padding: 0 16px 0;
        overflow-y: hidden;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .position-input-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .p-autocomplete-panel.p-component.security-search-record-position-panel {
        width: 100%;
        border-radius: 0px;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.header-content {
    font-size: 14px;
    color: black;
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
}

.save-button {
    border-radius: 50px;
    padding: 8px 64px;
    font-size: 18px;
}

.form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quote-data-section {
    padding: 16px 16px 0;
}

.input-section:first-of-type {
    margin-top: 32px;
}
.input-section {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-bottom: 32px;
}
.input-section:last-of-type {
    margin-bottom: 8px;
}
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    font-size: 12px;
}
.p-float-label ::v-deep(.p-placeholder) {
    opacity: 1;
}

.dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.dropdown ::v-deep(.p-inputtext) {
    padding: 0;
}
.dropdown:hover, .dropdown:active {
    border-color: #33CC99;
}
.dropdown:focus, .dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}
::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}
.dropdown.market-order-selected {
    pointer-events: none;
}
.account-dropdown {
    margin-bottom: 12px;
}

.p-input-icon-right,
.p-inputnumber {
    width: 100%;
}
.p-inputnumber > ::v-deep(.p-inputtext::placeholder) {
    color: #bfbfbf;
}
.p-inputnumber > ::v-deep(.p-inputtext) {
    border-radius: 8px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    width: 100%;
    padding: 8px 0px 8px 16px;
    color: black;
}
.p-inputnumber:hover > ::v-deep(.p-inputtext) {
    border-color: #33CC99;
}
.p-inputnumber:focus > ::v-deep(.p-inputtext) {
    box-shadow: none;
    border-color: #33CC99;
}

.security-search-bar-container {
    width: 100%;
}
.p-autocomplete {
    width: 100%;
}
::v-deep(.p-autocomplete-input.p-inputtext.p-component){
	border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    padding: 8px 8px 8px 36px;
    color: #32364e;
    font-size: 16px;
	width: 100%;
}
::v-deep(.p-autocomplete-input.p-inputtext.p-component::placeholder) {
    font-size: 16px;
    color: #BFBFBF;
}

@media (max-width: 760px) {


    /* note this is needed to stop mobile zooming in when entering text */
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
        background: #eee;
    }
}
</style>