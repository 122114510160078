<template>
    <Dialog ref='viewMotionModal' header="Motion" v-model:visible="display" :modal="true" :closable="true"
        class='view-motion-modal'>
        <LoadingSpinner :showLoading="showLoading" v-if="showLoading" style="width: 100%; height:400px;" />
        <div v-if="!showLoading && motion" class="review-motion-section">
            <div class="trade-details-section">
                <span class="trade-details-header">Trade Details</span>
                <div class="trade-details-content">
                    <p>
                        {{ motionTradeOrder?.description }}<br>
                        <b>Portfolio: {{motion.portfolioName}}</b><br>
                        <b>Account: {{motion.accountName}}</b>
                    </p>
                </div>
            </div>

            <div class="motion-details-section">
                <div class="motion-details-header">Motion Details</div>
                <div class="voting-deadline-section">
                    <span class="sub-header">Voting Deadline:</span> 
                    {{ motion.deadline + (motion.deadline == 1 ? ' hour' : ' hours')}} ( <b>{{ newDeadlineTime() }}</b> Approx.)
                </div>

                <div class="summary-section">
                    <span class="sub-header">Summary:</span>
                    <ul>
                        <template
                            v-for="point of summaryPoints">
                            <li v-if="point" :key="point" v-html="point">
                               
                            </li>
                        </template>
                    </ul>
                </div>

                <div class="rationale-section" v-if="motion.details">
                    <span class="sub-header">Detailed Rationale:</span>
                    <div v-if="motion.details" v-html="motion.details"></div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Close" class='p-button-raised p-button-rounded' @click='close' />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import LoadingSpinner from '../../common/LoadingSpinner.vue';

import MotionService from '../../../service/MotionService';
import { BUY_TRADE_TYPE } from '../../../common/constants';
import TimeUtils from '../../../utilities/TimeUtils';

export default {
    name: 'ViewMotionModal',
    
    data() {
        return {
            BUY_TRADE_TYPE,

            display: false,
            showLoading: false,

            motion: null,

            orderTypeOption: null,
        }
    },

    components: {
    Dialog,
    LoadingSpinner
},

    computed: {
        motionTradeOrder(){
            return this.motion.motionTradeOrders[0]?.tradeOrder;
        },

        summaryPoints(){
            return [this.motion.summaryPoint1, this.motion.summaryPoint2, this.motion.summaryPoint3, this.motion.summaryPoint4, this.motion.summaryPoint5];
        },
    },

    methods: {
        open(motion) {
            this.display = true;

            this.showLoading = true;
            MotionService.getMotion(motion.motionId).then(resp => {
                this.showLoading = false;

                if (resp.data.status == "success") {
                    this.motion = resp.data.motion;
                } else {
                    console.error("error getting account motions:" + resp.data.message)
                }
            });
        },
        close() {
            this.display = false;
        },

        newDeadlineTime() {
            const date = new Date(this.motion.deadlineDate);
            return TimeUtils.getFormattedTime(date, 'YYYY/MM/DD HH:mm');
        },

    }

}
</script>

<style>
.view-motion-modal {
    font-family: "Trebuchet MS", "Verdana";
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    width: 600px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.view-motion-modal .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.view-motion-modal .p-dialog-header .p-dialog-title {
    font-weight: bold;
    font-size: 24px;
}

.view-motion-modal .p-dialog-content {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    padding: 16px;
}

.view-motion-modal .p-dialog-footer {
    padding-top: 1.5rem;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
</style>
<style scoped>
*:not(.pi, .material-symbols-outlined),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.sub-header {
    font-weight: bold;
    color: #32364e;
}

.trade-details-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.trade-details-section .trade-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}
.trade-details-section .trade-details-content ::v-deep(b),
.trade-details-section .trade-details-content b {
    color: black;
}

.motion-details-section {
    display: flex;
    flex-direction: column;
}
.motion-details-section .motion-details-header {
    font-weight: bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}
.motion-details-section .voting-deadline-section {
    margin-bottom: 20px;
}
.motion-details-section .summary-section {
    margin-bottom: 20px;
}
.motion-details-section .summary-section ul {
    word-wrap: break-word;
}
.motion-details-section .rationale-section {
    word-wrap: break-word;
}
.motion-details-section .rationale-section ::v-deep(img) {         
    max-width: 100%;
    padding: 8px 0px;
}
.motion-details-section .rationale-section ::v-deep(.image) {
    margin: 0 auto !important;
}
.motion-details-section .rationale-section ::v-deep(.image.image-style-side) {
    float: right;
}
.motion-details-section .rationale-section ::v-deep(.image img) {
    max-width: 100%;
    max-height: 100%;
    width: unset;
    height: unset;
}
</style>