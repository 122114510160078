<template>
 
    <DataTable v-if="userHasHoldings" id='holdingsTransactionsTable' :value="holdings" :scrollable="true" scrollHeight="500px" :rowClass="portfolioRowClass" :resizableColumns="true" columnResizeMode="expand">
      <template > 
         <Column :header="(isMobile? 'Buy/Sell' : 'Actions')" headerClass="holdings-header" class="action-column" :frozen="true">
          <template #body="slotProps"> 
            <template v-if="!isMobile">
              <div @click="goToOverview(slotProps.data)"  class="p-button p-component p-button-icon-only view-sec-button" v-tooltip.top="'Go to company overview'" >
                  <span class="pi pi-chart-line p-button-icon"></span><span class="p-button-label">&nbsp;</span>
                </div>
                <!-- <div @click="goToPosts(slotProps.data)"  class="p-button p-component p-button-icon-only view-sec-button " v-tooltip.top="'Go to Posts'" >
                  
                  <span class="material-symbols-outlined p-button-icon " >speaker_notes</span>
                </div> -->
                <div v-if="!competitionPage" @click="paperAccountActionHandler(slotProps.data)"  class="p-button p-component p-button-icon-only view-sec-button " 
                  v-tooltip.top="selectedAccount?.accountTypeId != PAPER_ANALYSIS_ACCOUNT_TYPE['ID'] ? 'Buy/Sell' : 'Update Position'" >
                  
                  <img src="/assets/images/trade.png"  v-if="selectedAccount?.accountTypeId != PAPER_ANALYSIS_ACCOUNT_TYPE['ID']"/>
                  <span class="p-button-icon " v-else>
                    <i class='pi pi-pencil'></i>
                  </span>
                </div>
            </template>
            <template v-else>
                <div @click="paperAccountActionHandler(slotProps.data)"  class="p-button p-component p-button-icon-only view-sec-button " v-tooltip.top="'Buy/Sell'" v-if="accountTypeCanTrade || selectedAccount?.accountTypeId === PAPER_ANALYSIS_ACCOUNT_TYPE['ID']">
                  
                  <img src="/assets/images/trade.png" v-if="selectedAccount?.accountTypeId != PAPER_ANALYSIS_ACCOUNT_TYPE['ID']"/>
                  <span class="p-button-icon " v-else>
                    <i class='pi pi-pencil'></i>
                  </span>
                </div>
            </template>
             
          </template>
        </Column>
      </template>
      <Column field="symbol" header="Symbol" headerClass="holdings-header" class="symbol-column" :frozen="true" :style="(isMobile? 'padding-left:unset;': '')">
        <template #body="slotProps">
          <template v-if="!isMobile">
            <div class="ticker" v-if="!isMobile">
              {{slotProps.data.otherAsset?.proxySymbol || slotProps.data.otherAsset?.symbol || slotProps.data.derivative?.symbol || slotProps.data.securityListing?.symbol || slotProps.data.fund?.symbol}}
            </div>
          </template>
         
          <template v-else>
                <a href="#" @click="goToOverview(slotProps.data)">
                {{slotProps.data.otherAsset?.proxySymbol || slotProps.data.otherAsset?.symbol || slotProps.data.derivative?.symbol || slotProps.data.securityListing?.symbol || slotProps.data.fund?.symbol}}
                </a>
             
          </template>
          
        </template>
      </Column>
      
      <Column field="name" header="Name" headerClass="holdings-header" class="name-column">
        <template #body="slotProps">
          <div class="company" v-tooltip.top="{value: companyNameProcessor(slotProps.data), class:'company-name-tooltip'}">
            <!-- <template v-if="isMobile">
              {{slotProps.data.otherAsset?.proxySymbol || slotProps.data.otherAsset?.symbol || slotProps.data.derivative?.symbol || slotProps.data.securityListing?.symbol || slotProps.data.fund?.symbol}}&nbsp;
            </template> -->
            <a href="#" @click="goToOverview(slotProps.data)"> {{companyNameProcessor(slotProps.data)}}</a>
          </div>
        </template>
      </Column>
      <Column field="weight" header="Weight" headerClass="holdings-header" class="weight-column" >
        <template #body="slotProps">
          <div class="weight">
            {{ valueFormatter((slotProps.data.weight * 100), 'percentage') }}
          </div>
        </template>
      </Column>
      <Column field="quantity" header="Quantity" headerClass="holdings-header" class="quantity-column" :hidden="isCommunityPortfolio">
        <template #body="slotProps">
          <div class="quantity" v-if="slotProps.data.quantity && canShowDollarAmount()">
            {{ slotProps.data.quantity }}
          </div>
          <div class="no-value" v-else>
            -
          </div>
        </template>
      </Column>
      
      <!-- portfolio stats view -->
      <template v-if="!viewTrades">
        <Column field="avgCostPerUnit" header="Avg Cost Per" headerClass="holdings-header" class="avg-cost-per-column"  v-if="canShowAvgCost()">
          <template #body="slotProps">
            <div class="avg-cost-per">
              {{ (canShowAvgCost() ? valueFormatter((slotProps.data.avgCostPerUnit), 'currency'): '-') }}
            </div>
          </template>
        </Column>
        <Column field="marketValue" header="Market Value" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div class="market-value" v-if="slotProps.data.marketValue">
              {{ (canShowDollarAmount() ? valueFormatter(slotProps.data.marketValue, 'currency'): '-') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
        <Column field="bookValue" header="Book Value" headerClass="holdings-header" class="book-column">
          <template #body="slotProps">
            <div class="book-value" v-if="slotProps.data.bookValue">
              {{ (canShowDollarAmount() ? valueFormatter(slotProps.data.bookValue, 'currency') : '-') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
        <Column v-if="!competitionPage" field="gainAmount" header="$ Gain/Loss" headerClass="holdings-header" class="gain-loss-column" >
          <template #body="slotProps">
            <div :class="valueClass(slotProps.data, 'gainAmount')" v-if="slotProps.data.marketValue && slotProps.data.bookValue">
              {{ valueFormatter((slotProps.data.marketValue - slotProps.data.bookValue), 'currency') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
        <Column v-if="!competitionPage" field="gainPercent" header="% Gain/Loss" headerClass="holdings-header" class="gain-loss-column" >
          <template #body="slotProps">
            <div :class="valueClass(slotProps.data, 'gainPercent')" v-if="slotProps.data.marketValue && slotProps.data.bookValue">
              {{ valueFormatter(calcGainPercent(slotProps), 'percentage') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
      </template>

      <!-- trading view -->
      <template v-if="viewTrades">
         <Column field="last" header="Last" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div :class="'market-value ' + getTickColor(slotProps.data.changepercent)" v-if="slotProps.data.last">
              {{ valueFormatter(slotProps.data.last, 'currency') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
         <Column field="change" header="%Change" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div :class="'market-value ' + getTickColor(slotProps.data.changepercent)" v-if="slotProps.data.changepercent">
              {{ valueFormatter(slotProps.data.changepercent, 'percentage') }}
              
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
        <Column field="yield" header="Yield" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div :class="'market-value ' " v-if="slotProps.data.yield">
              {{slotProps.data.yield }}
              
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
         <Column field="bid" header="Bid" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div class="market-value" v-if="slotProps.data.bid">
              {{ valueFormatter(slotProps.data.bid, 'currency') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
         <Column field="ask" header="Ask" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div class="market-value" v-if="slotProps.data.ask">
              {{ valueFormatter(slotProps.data.ask, 'currency') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
         <Column field="tradevolume" header="Trade Vol." headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div class="market-value" v-if="slotProps.data.tradevolume">
              {{ slotProps.data.tradevolume}}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
        <Column field="prevclose" header="Prev. Close" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div class="market-value" v-if="slotProps.data.prevclose">
              {{ valueFormatter(slotProps.data.prevclose, 'currency') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
        <Column field="close" header="Close" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div class="market-value" v-if="slotProps.data.close">
              {{ valueFormatter(slotProps.data.close, 'currency') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
        <Column field="open" header="Open" headerClass="holdings-header" class="market-column" >
          <template #body="slotProps">
            <div class="market-value" v-if="slotProps.data.open">
              {{ valueFormatter(slotProps.data.open, 'currency') }}
            </div>
            <div class="no-value" v-else>
              -
            </div>
          </template>
        </Column>
        
      </template>
      
    </DataTable>
    <p class="no-holdings-text" v-else>We were unable to load any non-cash holdings for this account.</p>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import {PAPER_ANALYSIS_ACCOUNT_TYPE, PAPER_TRADING_ACCOUNT_TYPE, COMPETITION_ACCOUNT_TYPE} from '../../../common/constants';
import ResearchService from '../../../service/ResearchService';
import BrowserUtils from '../../../utilities/BrowserUtils';
import FormattingUtils from '../../../utilities/FormattingUtils';
// import SpeedDial from 'primevue/speeddial';
import EventBus from '../../../event-bus';

export default {
  name: 'HoldingsTable',
  emits: ['update-position'],
  props: {
    holdings: {
      required: true,
    },

    holdingsView: {
      type: String
    },

    selectedAccount: {
      type: Object,
      required: false
    },

    competitionPage: {
      type: Boolean,
      required: false,
      default: false
    },

  },
  components: {
    DataTable,
    Column
    // SpeedDial
    
  },

  data() {
    return {
      //CONSTANTS
      PAPER_ANALYSIS_ACCOUNT_TYPE,
      PAPER_TRADING_ACCOUNT_TYPE,

      formatter: new FormattingUtils(),
      viewTrades: false,
      selectedRow: null,
      // mobileRowActions: [
      //     {
      //         label: 'Add',
      //         icon: 'pi pi-chart-line',
      //         command: () => {
      //             this.goToOverview(this.selectedRow);
      //         }
      //     },
      //     {
      //         label: 'Update',
      //         icon: 'pi pi-refresh',
      //         command: () => {
      //             this.$toast.add({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      //         }
      //     }]
    };
  },

  computed: {
    userHasHoldings() {
        
        //console.log("this.holdings", this.holdings);
      return this.holdings.length > 0;
    },

    isMobile() {
      return BrowserUtils.isMobile();
    },

    isCommunityPortfolio() {
      return this.selectedAccount?.investmentAccountId === -999;
    },

    accountTypeCanTrade() {
      return [PAPER_TRADING_ACCOUNT_TYPE['ID'], COMPETITION_ACCOUNT_TYPE['ID']].includes(this.selectedAccount?.accountTypeId) || this.isCommunityPortfolio || this.selectedAccount?.investmentAccountId === -998;
    },

    paperAccountActionTooltip() {
      return this.accountTypeCanTrade ? 'Buy/Sell' : 'Update Position';
    }
  },
  watch: {
    // holdings() {
    //   console.log("holdings changed");
    // },

    holdings: {
        handler: function() {
              //console.log("holdings changed");
        },
        deep: true
    },

    holdingsView(val) {
      
        //console.log("holdingsView changed to "+val);
        if(val == 'Trading') {
          this.viewTrades = true;


        }
        else {
          this.viewTrades = false;
        }

        if (this.isMobile) {
          this.scrollToWeightColumn();
        }
      }
    },

  created() {
    // console.log(this.holdings);
  }, 
  mounted() {
    //console.log("selected acount", this.selectedAccount)
    if (this.isMobile) {
      this.$nextTick( () => {
         this.scrollToWeightColumn();
      });
     
    }
  },

  methods: {

    calcGainPercent(slotProps){
      
      if( slotProps.data.quantity < 0 ) {
       
        let val = ((Math.abs(slotProps.data.bookValue)/Math.abs(slotProps.data.marketValue) - 1) * 100);
        if( slotProps.data.marketValue < slotProps.data.bookValue) {
          if( val > 0 ) {
            return val* -1;
          }
          else {
            return val;
          }
        }
        else {
          return val;
        }
      }
      else {
        return ((slotProps.data.marketValue / slotProps.data.bookValue - 1) * 100);
      }
      
      
      
    },
    canShowDollarAmount() {
     
        return this.isOwnAccount() || this.isPortfolioSummaryView() || this.isGroupAggregatePortfolioAndShowDollarAmount()
          || this.isGroupCollaborativePortfolio() || this.isGroupCollaborativePorfolioAccount();
    },

    isGroupAggregatePortfolioAndShowDollarAmount() {
      return(this.selectedAccount && this.selectedAccount?.groupPortfolioId && this.selectedAccount?.showDollarAmounts );
    },

    isGroupCollaborativePortfolio(){
       return(this.selectedAccount && this.selectedAccount?.groupPortfolioId && this.selectedAccount?.groupPortfolioTypeId == 2);
    },

    isGroupCollaborativePorfolioAccount() {
      return(this.selectedAccount && this.selectedAccount?.groupPortfolioId && this.selectedAccount?.collaborative) ;
    },

    canShowAvgCost() {
       return this.isOwnAccount();
    },

    isPortfolioSummaryView() {
      return (this.selectedAccount && this.selectedAccount?.investmentAccountId == -998)
    },

    isOwnAccount() {
        if( this.competitionPage || (this.selectedAccount && this.selectedAccount?.userId == this.$store.state.users.user.userId) ) {
            return true;
        }
        else {
            return false;
        }
    },
    scrollToWeightColumn() {
      const holdingsTransactionsTable = document.querySelector('#holdingsTransactionsTable .p-datatable-wrapper');
      if(holdingsTransactionsTable){
        holdingsTransactionsTable.scrollLeft = 200;
      }
    },

    companyNameProcessor(data) {
      return data.fund?.name || data.securityListing?.company || data.otherAsset?.name || data.derivative?.name
    },

    getTickColor(changepercent) {
      if( changepercent < 0 ) {
        return 'red-tick';
      }
      else {
        return 'green-tick';
      }
    },

    goToOverview( slotProps ) {
      let router = this.$router;
      let symbol = null;
      let exSymbol = null;
      if( slotProps.fund ) {
          symbol =  slotProps.fund?.symbol ;
          exSymbol = slotProps.fund?.exchangeSymbol;
          ResearchService.setSelectedFundFromXignite(exSymbol, symbol, (resp) => {
              if (resp == 'success') {
                  router.push({ path: '/fund' });
              }
          });
      }
      else if( slotProps.otherAsset ) {
        symbol =  slotProps.otherAsset?.symbol ;
        exSymbol = slotProps.otherAsset?.exchangeSymbol;
      ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
              if (resp == 'success') {
                router.push({ path: '/equity' });
              }
          });
      }
      else if( slotProps.securityListing ) {
        symbol =  slotProps.securityListing?.symbol 
         exSymbol = slotProps.securityListing?.exchangeSymbol;

          ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
              if (resp == 'success') {
                router.push({ path: '/equity' });
              }
          });
        
      }

    },

    paperAccountActionHandler(slotProps) {//eslint-disable-line
      //console.log(JSON.stringify(slotProps))
      if (this.selectedAccount?.accountTypeId != PAPER_ANALYSIS_ACCOUNT_TYPE['ID']) {
        const data = {
          security: slotProps.fund? slotProps.fund : slotProps.securityListing
        };
        //console.log("paper acciont handler with selected account ", this.selectedAccount);
        if( this.selectedAccount?.collaborative ) {// account level of collaborative portfolio
          EventBus.emit('open-motion-from-existing-position', data);
        }
        else if( this.selectedAccount?.groupPortfolioId && this.selectedAccount?.groupPortfolioTypeId == 2) { // collaborative portfolio
          EventBus.emit('open-motion-from-existing-position', data);
        }
        else { // all other scenarios open regular trading 

          EventBus.emit('open-trading-paper-accounts-modal', data);
        }
      } else {
        const data = {
          quantity: slotProps.quantity,
          security: slotProps.fund? slotProps.fund : slotProps.securityListing
        }

        this.$emit('update-position', data);
      }
    },

    goToPosts( slotProps ) {
      let router = this.$router;
      let symbol = null;
      let exSymbol = null;
      if( slotProps.otherAsset ) {
        symbol =  slotProps.otherAsset?.symbol ;
        exSymbol = slotProps.otherAsset?.exchangeSymbol;

        //console.log(JSON.stringify(slotProps) )
       ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
              if (resp == 'success') {
                router.push({ path: '/equity/notes' });
              }
          });
      }
      else if( slotProps.securityListing ) {
        symbol =  slotProps.securityListing?.symbol 
         exSymbol = slotProps.securityListing?.exchangeSymbol;

          ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
              if (resp == 'success') {
                router.push({ path: '/equity/notes' });
              }
          });
        
      }
    },
    
    valueClass(data, type) {
      if (type === 'gainAmount' || type === 'gainPercent') {
        return [
          {
            'positive': data.marketValue > data.bookValue,
            'negative': data.marketValue < data.bookValue,
            'even': data.marketValue === data.bookValue,
          }
        ];
      }
    }, 

    portfolioRowClass() {
      return 'portfolio-row';
    },

    valueFormatter(value, type) {
      if (type === 'currency') {
        return this.formatter.format(value, '$0.00');
      } else if (type === 'percentage') {
        const formattedValue = value.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 3
        }) + '%';
        return formattedValue;
      }
    }
  },
}
</script>

<style>
.company-name-tooltip {
  max-width: 300px;
}
</style>
<style scoped>
::v-deep(*:not(.pi, .material-icons-outlined, .material-symbols-outlined)), ::v-deep(.p-component){
    font-family: 'Trebuchet MS', 'Verdana';
}



.no-holdings-text {
  font-family: "Trebuchet MS";
  font-size: 12px;
  color: #999;
}

.negative, .positive, .ticker, .company, .weight, .quantity, .market-value, .book-value, .avg-cost-per {
  font-size: 12px;
}

.negative, .positive, .even, .ticker {
  font-weight: bold;
}

.negative {
  color: #e63e3e;
}

.positive {
  color: #3c9;
}

.ticker {
  color: #32364e;
}

.even, .company, .weight, .quantity, .market-value, .book-value, .no-value, .avg-cost-per {
  color: black;
}

::v-deep(.portfolio-row) {
  font-family: 'Trebuchet MS';
}

::v-deep(.holdings-header), ::v-deep(.p-column-title) {
  font-family: 'Trebuchet MS';
  font-weight: 400;
  font-size: 12px;
  background: #fff !important;
  color: #999;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th) {
  background: #fff;
}

.company {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

::v-deep(.weight-column){
  min-width: 70px;
  max-width: 70px;
  
}

::v-deep(.symbol-column) {
   min-width: reset;
    max-width: 98px;
   /* flex: none !important; */
}

::v-deep(.action-column) {
  min-width: 98px;
  max-width: 98px;
}



 @media (max-width: 760px) {
    ::v-deep(.action-column) {
      min-width: 68px;
      max-width: 68px;
    }


    ::v-deep(.symbol-column) {
      min-width: 68px;
      max-width: 68px;
      flex: none !important;
      
    }

    ::v-deep(.view-item-button) {
        
      background: #FFFFFF;
      border: 1px solid #33CC99;
      box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
      border-radius: 8px;
      color: #32364e;
      width: 100px;
  }
} 

::v-deep(.name-column) {
  min-width: 200px;
}

::v-deep(.market-column), ::v-deep(.book-column), ::v-deep(.gain-loss-column) {
  min-width: 70px;
}

::v-deep(.view-item-button) {
        /* margin-left: auto; */
    background: #FFFFFF;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
    border-radius: 8px;
    color: #32364e;
    /* padding: 8px 12px; */
    /* width: 4rem; */
    justify-content: center;

}

::v-deep(.view-item-button:hover) {
    border-color:#32364e;
    background-color: #33cc99;
    color: #FFFFFF;
}
::v-deep(.view-item-button:focus) {
    box-shadow: none;
    border-color: #33cc99;
}

::v-deep(.view-sec-button) {
   border: 1px solid #32364e;
    background-color: #FFFFFF;
    padding: 0px;
    height: 31px;
    color: #32364e;
    border-radius: 30px;
    /* flex: 0 1 47px; */
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-left:3px;
}
.view-sec-button > img {
  width: 24px;
}

::v-deep(.view-sec-button:hover) {
    border-color:#32364e;
    background-color: #33cc99;
    color: #FFFFFF;
}
::v-deep(.view-sec-button:focus) {
    box-shadow: none;
    border-color: #33cc99;
}

.green-tick {
  color: #33CC99;
}

.red-tick {
  color: #e63e3e;
}


::v-deep(.portfolio-row > td) {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
} 
</style>
