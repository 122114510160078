<template>
    <Dialog header='Update Cash Position' v-model:visible="display" :modal="true" class='cash-transaction-modal' @hide="onHide">
            <div class='header-content' v-if='account'>
                <span>Account: <b>{{account.name}}</b></span>
                <span>Currency: <b>{{currencyUtils.getCurrencyNameViaId(account.currencyId)}}</b></span>
                <span>Current Cash Position: <b>{{formatter.format(totalCashValue, '$0.00')}}</b></span>
            </div>

            <div class='form-section'>
                <!-- <div class='input-section'>
                    <div class="p-float-label">
                        <Dropdown id="transactionTypeDropdown" class="transaction-type-dropdown dropdown" v-model="transactionTypeOption" optionLabel="name" :options="transactionTypeOptions" />
                        <label for="transactionTypeDropdown" class='input-label'>Transaction Type</label>
                    </div>
                </div> -->


                <div class='input-section'>
                    <div class="p-float-label p-input-icon-right">
                        <i class="pi pi-dollar" />
                        <InputNumber id='amountInput' class='amount-input' v-model="amount" mode="decimal" :minFractionDigits="2"/>
                        <label for="amountInput" class='input-label'>New Cash Position</label>
                    </div>
                </div>
            </div>
        
        <template #footer>
			<Button label="Save" class='save-button green-button__primary' @click="recordTransaction" :disabled="disableSaveButton"/>
		</template>
	</Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber'

import CurrencyUtils from '../../../utilities/CurrencyUtils';
import PortfolioService from '../../../service/PortfolioService';
import EventBus from '../../../event-bus';
import { getTotalCash} from '../';
import FormattingUtils from '../../../utilities/FormattingUtils';

const DEPOSIT_TRANSACTION = {
    'ID': 14,
    'NAME': 'Deposit'
};

const WITHDRAWAL_TRANSACTION = {
    'ID': 33,
    'NAME': 'Widthdrawl'
};

export default {
    name: 'CashTransactionModal',
    components: {
        Dialog,
        InputNumber
    },

    created() {
        this.formatter = new FormattingUtils();
    },

    computed: {
        disableSaveButton() {
            return !this.amount;
        },
        totalCashValue() {
            return this.account?.cashPositions ? getTotalCash(this.account?.cashPositions) : 0;
        },
    },
    data() {
        return {
            display: false,
            formatter: null,
            amount: null,
            account: null,
            fromGroup: false,
            currencyUtils: CurrencyUtils,

            transactionTypeOption: null,
            transactionTypeOptions: [{
                id: DEPOSIT_TRANSACTION['ID'],
                name: DEPOSIT_TRANSACTION['NAME']
            }, {
                id: WITHDRAWAL_TRANSACTION['ID'],
                name: WITHDRAWAL_TRANSACTION['NAME']
            }],
            transactionTypeDropdownPlaceholder: 'Select a Transaction Type',
        }
    },
    

    methods: {
       

        open(data) {
            this.display = true;
            this.account = data.account;
            this.fromGroup = data.fromGroup;
        },

        onClose() {
            this.display = false;
        },

        onHide() {
            this.account = null;
            this.amount = null;
            this.transactionTypeOption = null;
        },

        recordTransaction() {
            this.loading = true;
           
            PortfolioService.recordCashPosition(this.account.investmentAccountId, this.amount).then(resp => {
                this.loading = false;
                if (resp.data.status === 'success') {
                    if (!this.fromGroup) {
                        EventBus.emit('reload-account');
                    } else {
                        EventBus.emit('reload-account-from-groups');
                    }
                    this.$toast.add({ severity: 'success', summary: 'Successfully updated cash position!', life: 3000, group: 'center'});
                    this.onClose();
                } else {
                    console.error('error updating cash position: ', + resp.data.message);
                    this.$toast.add({ severity: 'error', summary: resp.data.message, life: 3000, group: 'center'});
                }
            })
            .catch(err => {
                this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                console.log("unexpected updating cash position: "+err);
            })
            
        }
    }
}
</script>

<style>
.cash-transaction-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
}

.cash-transaction-modal .p-dialog-header {
    padding: 16px;
}

.cash-transaction-modal .p-dialog-content {
    padding: 0px 16px;
    overflow-y: visible;
}

.cash-transaction-modal .p-dialog-footer {
    padding: 16px 0px;
    text-align: center;
}

@media (max-width: 760px) {
    .cash-transaction-modal {
    
        height: 100vh!important;
        
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
        border-radius: 0;
    }

    .cash-transaction-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .cash-transaction-modal .p-dialog-content {
        padding: 0 16px 0;
        overflow-y: hidden;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .cash-transaction-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.header-content {
    font-size: 14px;
    color: black;
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
}

.save-button {
    border-radius: 50px;
    padding: 8px 64px;
    font-size: 18px;
}

.form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-section {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-bottom: 32px;
}
.input-section:last-of-type {
    margin-bottom: 8px;
}
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    font-size: 12px;
}
.p-float-label ::v-deep(.p-placeholder) {
    opacity: 1;
}

.p-dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.p-dropdown:hover, .p-dropdown:active {
    border-color: #33CC99;
}
.p-dropdown:focus, .p-dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label) {
    padding: 0px;
    font-size: 1rem;
    color: black;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}
::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}

.p-input-icon-right,
.p-inputnumber {
    width: 100%;
}
.p-inputnumber > ::v-deep(.p-inputtext::placeholder) {
    color: #bfbfbf;
}
.p-inputnumber > ::v-deep(.p-inputtext) {
    border-radius: 8px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    width: 100%;
    padding: 8px 0px 8px 16px;
    color: black;
}
.p-inputnumber:hover > ::v-deep(.p-inputtext) {
    border-color: #33CC99;
}
.p-inputnumber:focus > ::v-deep(.p-inputtext) {
    box-shadow: none;
    border-color: #33CC99;
}

@media (max-width: 760px) {


    /* note this is needed to stop mobile zooming in when entering text */
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
        background: #eee;
    }
}
</style>