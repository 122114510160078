<template>
    <Dialog header="Veto Motion" v-model:visible="display" :modal="true" :closable="true" :draggable="false"
        class="veto-motion-modal">

        <div class='reason-textarea-container'>
            <span :class="{ 'p-float-label': true, 'input-error': v$.reason.$error }">
                <Textarea id='reasonTextarea' rows="5" cols="30" v-model='v$.reason.$model' @input="resetError('reason')" />
                <label for='reasonTextarea' class='input-label'>Enter in a reason</label>
                <span class='reason-counter' :style="{ color: v$.reason.$error ? '#E63E3E' : null }">{{ (reason ?
                    reason.length : 0) }} / {{ reasonLimit }} </span>
            </span>
        </div>

        <template #footer>
            <Button class='p-button-raised p-button-rounded' label='Cancel' @click='close' />
            <Button class='p-button-raised p-button-rounded veto-button' label='Veto' @click='vetoMotion'
                :disabled="disableVetoButton()" />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';

import EventBus from '../../../event-bus';
import useVuelidate from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';
import MotionService from '../../../service/MotionService';

export default {
    name: 'VetoMotionModal',
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog, Textarea
    },
    data() {
        return {
            display: false,

            reason: '',
            reasonLimit: 500,
            motion: null,
        };
    },

    methods: {
        resetError(field) {
            this.v$[field].$reset();
        },

        disableVetoButton() {
            return this.v$.$errors.length > 0 ? 'disabled' : null;
        },

        open(motion) {
            this.v$.$reset();
            this.display = true;
            this.reason = '';
            this.motion = motion;
        },

        close() {
            this.display = false;
            this.motion = null;
        },

        vetoMotion() {
            this.display = false;

            if (this.motion) {
                MotionService.vetoMotion(this.motion.motionId).then(resp => {
                    if (resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: "Motion Vetoed.", detail: '', life: 3000, group: 'tc' });
                        EventBus.emit('refresh-motions');
                    } else {
                        console.error("error veto motion:" + resp.data.message)
                    }
                });
            }
        },
    },

    validations() {
        return {
            reason: {
                maxLength: maxLength(this.reasonLimit)
            },
        }
    }
};
</script>

<style>
.veto-motion-modal {
    font-family: 'Trebuchet MS', 'Verdana';
    width: 550px;
    border-radius: 16px;
}
</style>
<style scoped>
/* Shaking animation */
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(0.375rem);
    }

    30% {
        transform: translateX(-0.375rem);
    }

    45% {
        transform: translateX(0.375rem);
    }

    60% {
        transform: translateX(-0.375rem);
    }

    75% {
        transform: translateX(0.375rem);
    }

    90% {
        transform: translateX(-0.375rem);
    }

    100% {
        transform: translateX(0);
    }
}

.reason-textarea-container {
    margin-top: 24px;
}

.reason-textarea-container .p-inputtextarea {
    font-size: 16px;
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.reason-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}

.reason-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 9px solid #BFBFBF;
    /* creates padding around scroll thumb */
}

.reason-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.reason-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.reason-textarea-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.reason-textarea-container .reason-counter {
    font-size: 12px;
}

.reason-textarea-container .p-inputtextarea {
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.reason-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}

.reason-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 9px solid #BFBFBF;
    /* creates padding around scroll thumb */
}

.reason-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.reason-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.reason-textarea-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.reason-textarea-container .reason-counter {
    font-size: 12px;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.input-error .p-inputtext:hover,
.input-error .p-inputtext:enabled:focus,
.input-error .p-inputtext {
    border-color: #E63E3E;
}

.input-error .input-label {
    color: #E63E3E;
}

.p-dialog-footer .p-button {
    background: #FFFFFF;
    font-family: "Trebuchet MS", "Verdana";
    font-weight: 600;
    padding: 0.75rem 2.75rem;
    color: #32364E;
    border: 2px solid
}

.p-dialog-footer .p-button:hover {
    color: #FFFFFF;
    background: #32364E;
    border: 2px solid #32364E;
}

.veto-button {
    border-color: #E63E3E !important;
}

.veto-button:hover {
    color: #FFFFFF !important;
    background: #E63E3E !important;
    border: 2px solid #E63E3E !important;
}
</style>