<template>
  
    <LoadingSpinner :showLoading="!loaded" style="width: 100%; height: 100%" text="Calculating Top Performers for past month..."/>
   <div class='user-list-item-container' >
     <div class='text-container' style="color: #999;" >User</div>
      <div class='buttons-container' style="color: #999;">Ranking</div>
    </div>
    
      <ScrollPanel :style="'width: 100%; '+(isMobile()? 'height: 200px;' : 'height: auto;')">
    <div class='user-list-item-container' v-for="user in performers" :key="user.userId">
        <UserAvatar :imageSrc='user.avatar' size='md' @click='userAvatarClick(user,$event)'/>
          <div class='text-container'>
            <span class='header-text' @click='headerTextClick(user, $event)'>
                {{stringUtils.fullName(user)}}
            </span>
            <span class='sub-text' @click='subTextClick(user, $event)'>
                @{{user.name}}
            </span>
            <span class='sub-text' @click='subText2Click(user, $event)' >
               
            </span>
        </div>
        <div class='buttons-container'>
            {{user.ranking}}
        </div>
        
    </div>
    </ScrollPanel>
    
   
</template>

<script>
// import DataTable from 'primevue/datatable';
// import Column from 'primevue/column';
import PortfolioService from '../../../service/PortfolioService';
import BrowserUtils from '../../../utilities/BrowserUtils';
import UserAvatar from '../../profile/UserAvatar.vue';
import PostUtils from '../../../utilities/PostUtils';
import StringUtils from '../../../utilities/StringUtils';
import UserUtils from '../../../utilities/UserUtils';
import ScrollPanel from 'primevue/scrollpanel'
import LoadingSpinner from '../../../components/common/LoadingSpinner.vue';
//import InfiniteScroll from '../../common/scroll/infinite-scroll-vue3.vue';  
export default {
  name: 'TopPerformersTable',
  props: {
   

   

  },
  components: {
   UserAvatar,ScrollPanel,LoadingSpinner,
   //InfiniteScroll
    
  },

  data() {
    return {
      stringUtils: StringUtils,
      userUtils: UserUtils,
      scrollStyle: "width: 100%; height: 200px;",
      performers: [],
      loaded: false,
    };
  },

  computed: {
    

    


  },

    

    

  created() {
    // console.log(this.holdings);
  }, 
  mounted() {
    //if( this.selectedAccountData) {
      this.getTopPerformersData();//this.selectedAccountData.investmentAccountId);
   // }
  },

  methods: {
      isMobile() {
        return BrowserUtils.isMobile();
       },
   
    getTopPerformersData() {
            this.loaded = false;
            //if( accountId == -999) {
                PortfolioService.getTopPerformers().then( resp => {
                    if( resp.data.status == 'success') {
                        this.performers = resp.data.performers;
                        this.$store.commit('SET_TOP_PERFORMERS', this.performers);

                    }
                    this.loaded = true;
                });
            //}
        },

     headerTextClick(user, event) {
            PostUtils.goToAnalystProfile(user.name, event);
            
        },
        subTextClick(user) {
            PostUtils.goToAnalystProfile(user.name, event);
            
        },
        subText2Click(user) {
            PostUtils.goToAnalystProfile(user.name, event);
            
        },
        userAvatarClick(user) {
            PostUtils.goToAnalystProfile(user.name, event);
            
        }, 

  }
}
</script>

<style scoped>
.user-list-item-container {
    display: flex;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #BFBFBF;
}
.user-list-item-container:last-child {
    border-bottom: none;
}

::v-deep(.p-avatar) {
    margin-right: 16px;
}
::v-deep(.p-avatar:hover) {
    cursor: pointer;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.header-text {
    font-size: 16px;
    color: #32364e;
    font-weight: bold;
    margin-bottom: 4px;
}
.header-text:hover {
    cursor: pointer;
}

.sub-text {
    font-size: 12px;
    color: #BFBFBF;
}
.sub-text:hover {
    cursor: pointer;
}

.buttons-container {
    margin-left: auto;
}
.buttons-container > ::v-deep(*) {
    margin-right: 8px;
}
.buttons-container > ::v-deep(*:last-child) {
    margin-right: 0px;
}
</style>
