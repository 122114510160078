import {CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE, CANADIAN_COUNTRY_ID, US_COUNTRY_ID, CURRENCY_TYPES} from '../common/constants'

class CurrencyUtilsImpl {
    getCurrencyViaCountry(countryId) {
        switch(countryId) {
            case CANADIAN_COUNTRY_ID:
                return CANADIAN_CURRENCY_TYPE['ID'];
            case US_COUNTRY_ID:
                return US_CURRENCY_TYPE['ID'];
            default:
                break;
        }
    }

    getCurrencyNameViaId(currencyId) {
        return CURRENCY_TYPES[currencyId]['NAME'];
    }

    getCurrencySymbolViaId(currencyId) {
        return CURRENCY_TYPES[currencyId]['SYMBOL'];
    }
}

const CurrencyUtils = new CurrencyUtilsImpl();
export default CurrencyUtils;