<template>
    <div class="tab-content">
        <LoadingSpinner :showLoading="!portfolioLoaded && !groupPortfolioLoaded" style="width: 100%; height: 100%" />
        <div class="p-grid" v-if="portfolioLoaded || groupPortfolioLoaded">
            <div class="left-col p-col-12 p-md-8">
                <PerformanceChart
                    :totalMarketValue="totalMarketValue"
                    :totalReturnAmount="totalReturnAmount"
                    :portfolioReturns="percentReturnSeries"
                    :returnDates="returnDateSeries"
                    :comparisonReturns="comparisonReturnSeries"
                    :isCryptoAccount="isCryptoAccount"
                    :viewingOwnPortfolio="true"
                    :selectedAccount="selectedAccountData"
                    :comparisonIndex="comparisonIndex"
                />
            </div>
            <div class="right-col p-col-12 p-md-4">
                <AllocationChart :sectorLabels="sectorLabels" :sectorValues="sectorValues" :assetLabels="assetLabels" :assetValues="assetValues" :holdingLabels="holdingLabels" :holdingValues="holdingValues" 
                :viewingOwnPortfolio="true" v-if='!isCryptoAccount'/>
            </div>

            <div class="card selected-account-card" v-if="!isAggregatedPortfolio && !isCommunityPortfolio && !isTeamPortfolio">
                <div class='grid-header'>
                    <div class="grid-header-text">Account Details </div>
                    <div class="account-details-buttons-container">
                        <Button class="p-button-raised p-button-rounded green-button__secondary transfer-funds-button" @click="startTransfer()"
                                    v-tooltip.bottom="'Transfer Playdough to an Investment Account'"
                                    v-if="showTransferButton()" >
                                    <img src="/assets/images/transfer_funds.png" />
                                    <span class="p-button-label">Transfer Funds</span>
                    </Button>
                     <Button class="p-button-raised p-button-rounded green-button__secondary buy-playdough-button" @click="buyPlaydough(acct)" 
                                        v-tooltip.bottom="'Buy Playdough'"
                                        v-if="showBuyPlaydoughButton()" >
                                        <img src="/assets/images/playd-icon.png" />
                                    <span class="p-button-label">Buy Playdough</span>
                                    </Button>
                     
                    <Button v-show="isCompetitionAccount && competitionResetsAllowed && isOwnAccount()" label='Reset' class='p-button-rounded cash-position-button reset-button' @click='onResetCompetitionAccount' icon="pi pi-replay" v-tooltip.bottom="'Reset your competition account to its starting value and position.'"/>
               
                    </div>
                </div>
                <div class='p-grid' v-if="!isMobile()">
                    <div class='p-sm-12 left-col' style="width:100%;">
                    <p>Name:<span style="margin-left:5px;">{{selectedAccountData?.name ? selectedAccountData?.name : selectedAccountData?.accountName}}</span></p>
                    </div>
                    <div class='p-sm-6 left-col' > 
                        
                        <table >
                           
                            <tr>
                                <td class='left-col'><p>Type:</p></td>
                                <td class='right-col'><p>{{accountTypeProcessor(selectedAccountData.accountTypeId)}} {{isTradingEnabledAccount ? '(50% Margin)': null}}</p></td>
                            </tr>
                           
                            
                        </table>
                    </div>
                    <div class='p-sm-6 right-col'>
                         <table >
                             <tr v-if='selectedAccountData.currencyId !== undefined'>
                                <td class='left-col'><p>Currency:</p></td>
                                <td class='right-col' ><p>{{(isTradingEnabledAccount ? 'Playdough ': '')}}{{currencyValueProcessor(selectedAccountData.currencyId)}}</p></td>
                            </tr>
                        </table>
                    </div>
                     <div class='p-sm-6 left-col'>
                        
                        <table>
                            <tr>
                                <td class='left-col'><p>Total Value:</p></td>
                                <td class='right-col'>
                                    <p >{{(canShowDollarAmount() ? formatter.format(totalValue, '$0.00') : '-')}}</p>
                                </td>
                            </tr>
                            <tr >
                                <td class='left-col'><p>Total {{(isTradingEnabledAccount ? 'Playdough Cash': 'Cash')}}:</p></td>
                                <td class='right-col'>
                                    <p >{{(canShowDollarAmount() ? formatter.format(totalCashValue, '$0.00') : '-')}}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class='p-sm-6 right-col'>
                        <table>
                            
                            <tr v-if="isTradingEnabledAccount">
                                <td class='left-col'><p>Total Borrowed:</p></td>
                                <td class='right-col'><p>{{(canShowDollarAmount() ? formatter.format(selectedAccountData.totalBorrowed, '$0.00') : '-')}}</p></td>
                            </tr>
                            <tr v-else>
                                 <td class='left-col'><p>Total Borrowed:</p></td>
                                <td class='right-col'><p>n/a</p></td>
                            </tr>
                            <tr v-if="isTradingEnabledAccount">
                                <td class='left-col'><p>Buying Power:</p></td>
                                <td class='right-col'><p>{{(canShowDollarAmount() ? formatter.format(selectedAccountData.currentBuyingPower, '$0.00') : '-')}}</p></td>
                            </tr>
                             <tr v-else>
                                <td class='left-col'><p>Buying Power:</p></td>
                                <td class='right-col'><p>n/a</p></td>
                            </tr>
                        </table>
                        <div class='paper-analysis-trade-buttons' v-if="selectedAccountData.accountTypeId === PAPER_ANALYSIS_ACCOUNT_TYPE['ID'] && !isMobile() && isOwnAccount()">
                        <Button label='Update Cash' class='cash-position-button green-button__secondary' @click='onEnterCashTxnClick' icon="pi pi-money-bill"/>
                        </div>
                    </div>
                    <div class='p-sm-12' style="width:100%;" v-if="selectedAccountData.suspensionDate != null && selectedAccountData.suspensionReason == 'Margin Call'">
                            <span style="color:red;">
                                Warning:  Margin Call - your total equity value no longer covers the amount borrowed.  You can only sell long positions or buy short positions until reconciled.
                            </span>
                           
                    </div>
                </div>
                <div class='p-grid' v-else>
                    <div class='p-sm-12 left-col' style="width:100%;">
                    <p>Name:<span style="margin-left:5px;">{{selectedAccountData?.name ? selectedAccountData?.name : selectedAccountData?.accountName}}</span></p>
                    </div>
                    <div class='p-sm-12 left-col' style="width:100%;"> 
                        
                        <table >
                           
                            <tr>
                                <td class='left-col'><p>Type:</p></td>
                                <td class='right-col'><p>{{accountTypeProcessor(selectedAccountData.accountTypeId)}} {{isTradingEnabledAccount ? '(50% Margin)': null}}</p></td>
                            </tr>
                           
                            
                        </table>
                    </div>
                    <div class='p-sm-12 left-col' style="width:100%;">
                         <table >
                             <tr v-if='selectedAccountData.currencyId !== undefined'>
                                <td class='left-col'><p>Currency:</p></td>
                                <td class='right-col' ><p>{{(isTradingEnabledAccount ? 'Playdough ': '')}}{{currencyValueProcessor(selectedAccountData.currencyId)}}</p></td>
                            </tr>
                        </table>
                    </div>
                     <div class='p-sm-12 left-col'  style="width:100%;">
                        
                        <table>
                            <tr>
                                <td class='left-col'><p>Total Value:</p></td>
                                <td class='right-col'>
                                    <p >{{(canShowDollarAmount() ? formatter.format(totalValue, '$0.00') : '-')}}</p>
                                </td>
                            </tr>
                            <tr >
                                <td class='left-col'><p>Total {{(isTradingEnabledAccount ? 'Playdough Cash': 'Cash')}}:</p></td>
                                <td class='right-col'>
                                    <p >{{(canShowDollarAmount() ? formatter.format(totalCashValue, '$0.00') : '-')}}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class='p-sm-12 left-col' style="width:100%;">
                        <table>
                            
                            <tr v-if="isTradingEnabledAccount">
                                <td class='left-col'><p>Total Borrowed:</p></td>
                                <td class='right-col'><p>{{(canShowDollarAmount() ? formatter.format(selectedAccountData.totalBorrowed, '$0.00') : '-')}}</p></td>
                            </tr>
                            <tr v-else>
                                 <td class='left-col'><p>Total Borrowed:</p></td>
                                <td class='right-col'><p>n/a</p></td>
                            </tr>
                            <tr v-if="isTradingEnabledAccount">
                                <td class='left-col'><p>Buying Power:</p></td>
                                <td class='right-col'><p>{{(canShowDollarAmount() ? formatter.format(selectedAccountData.currentBuyingPower, '$0.00') : '-')}}</p></td>
                            </tr>
                             <tr v-else>
                                <td class='left-col'><p>Buying Power:</p></td>
                                <td class='right-col'><p>n/a</p></td>
                            </tr>
                        </table>
                        <div class='paper-analysis-trade-buttons' v-if="selectedAccountData.accountTypeId === PAPER_ANALYSIS_ACCOUNT_TYPE['ID'] && !isMobile() && isOwnAccount()">
                        <Button label='Update Cash' class='cash-position-button green-button__secondary' @click='onEnterCashTxnClick' icon="pi pi-money-bill"/>
                        </div>
                    </div>
                    <div class='p-sm-12' style="width:100%;" v-if="selectedAccountData.suspensionDate != null && selectedAccountData.suspensionReason == 'Margin Call'">
                            <span style="color:red;">
                                Warning:  Margin Call - your total equity value no longer covers the amount borrowed.  You can only sell long positions or buy short positions until reconciled.
                            </span>
                           
                    </div>
                </div>

                <div class='paper-analysis-trade-buttons' v-if="selectedAccountData.accountTypeId === PAPER_ANALYSIS_ACCOUNT_TYPE['ID'] && isMobile() && isOwnAccount()">
                <Button label='Update Cash' class='cash-position-button green-button__secondary' @click='onEnterCashTxnClick' icon="pi pi-money-bill"/>
                </div> 
               
            </div>

             <div class="card overview-table" v-if="isCommunityPortfolio && isMobile()">
                <div class="table-header-container">
                    <div class="holdings-wrapper" >
                        <h2 class="table-header">Top Performers</h2>
                        <i class="pi pi-info-circle" v-tooltip.right="'Based on average daily returns over past month'"></i>
                    </div>
                </div>
                <TopPerformersTable />
            </div> 
            <div class="card overview-table">
                <div class="table-header-container">
                    <div class="holdings-wrapper" v-if="holdingsTableOn">
                        <h2 class="table-header">{{ (isCommunityPortfolio ? "Top 10 Holdings": "Holdings")}}</h2>
                        <i class="pi pi-info-circle" v-tooltip.right="holdingsDescription"></i>
                         <div class='paper-analysis-trade-buttons' v-if="selectedAccountData.accountTypeId === PAPER_ANALYSIS_ACCOUNT_TYPE['ID'] && isMobile() && isOwnAccount()">
                            <Button label='Add Position' class='enter-position-button green-button__secondary' @click='onEnterPositionClick' style="padding:0.3rem 0.5rem;">

                            <span class="material-symbols-outlined">list_alt_add</span><span>&nbsp;Add Position</span>
                            </Button>                            
                        </div>
                        <div class='paper-analysis-trade-buttons' v-if="isTradingEnabledAccount && isMobile() &&  (isOwnAccount() || selectedAccountData.collaborative)">
                        <Button class="p-button-raised p-button-rounded green-button__secondary enter-trade-button"  @click="onTradingButtonClick" v-tooltip.bottom="(selectedAccountData.collaborative ?'Place a Trade Motion': 'Place a Trade Order')"
                            style="padding:0.3rem 0.5rem;">
                            <img src="/assets/images/trade.png" /><span>&nbsp;Buy/Sell</span>
                        </Button>
                        </div>
                        <span style="margin-left:10px;" >
                    
                            <SelectButton :class="holdingsViewToggleClass" :options="holdingsViewOptions" v-model="holdingsView" v-if="!isMobile()" />
                        </span>
                        
                        <div class='paper-analysis-trade-buttons' v-if="selectedAccountData.accountTypeId === PAPER_ANALYSIS_ACCOUNT_TYPE['ID'] && !isMobile() && isOwnAccount()">
                            <Button label='Add Position' class='enter-position-button green-button__secondary' @click='onEnterPositionClick' style="padding:3px 12px;">
                            <span class="material-symbols-outlined">list_alt_add</span><span>&nbsp;Add Position</span>
                            </Button>    
                        </div>
                        <div class='paper-analysis-trade-buttons' v-if="isTradingEnabledAccount && !isMobile() &&  (isOwnAccount() || selectedAccountData.collaborative)">
                        <Button class="p-button-raised p-button-rounded green-button__secondary enter-trade-button"  @click="onTradingButtonClick" v-tooltip.bottom="(selectedAccountData.collaborative ?'Place a Trade Motion': 'Place a Trade Order')"
                            style="padding:3px 12px;">
                            <img src="/assets/images/trade.png" /><span>&nbsp;Buy/Sell</span>
                        </Button>
                        </div>
                    </div>
                    <div class="transactions-wrapper" v-if="transactionsTableOn">
                        <h2 class="table-header">Transactions</h2>
                        <i class="pi pi-info-circle" v-tooltip.right="'The holdings table shows details for all of the transactions in your investment account.'"></i>
                        <div class='paper-analysis-trade-buttons' v-if="isTradingEnabledAccount && !isMobile() && (isOwnAccount() || selectedAccountData.collaborative)">
                        <Button class="p-button-raised p-button-rounded green-button__secondary enter-trade-button"  @click="onTradingButtonClick" v-tooltip.bottom="(selectedAccountData.collaborative ?'Place a Trade Motion': 'Place a Trade Order')"
                            style="padding:3px 12px;">
                            <img src="/assets/images/trade.png" /><span>&nbsp;Buy/Sell</span>
                        </Button>
                        </div>
                    </div>
                    <div class="orders-wrapper" v-else-if="ordersTableOn">
                        <h2 class="table-header">Orders</h2>
                        <i class="pi pi-info-circle" v-tooltip.right="'The orders table shows details for all of the orders in your paper investment account.'"></i>
                        <div class='paper-analysis-trade-buttons' v-if="[PAPER_TRADING_ACCOUNT_TYPE['ID'], COMPETITION_ACCOUNT_TYPE['ID']].includes(selectedAccountData.accountTypeId) && !isMobile() && (isOwnAccount() || selectedAccountData.collaborative)">
                        <Button class="p-button-raised p-button-rounded green-button__secondary enter-trade-button"  @click="onTradingButtonClick" v-tooltip.bottom="(selectedAccountData.collaborative ?'Place a Trade Motion': 'Place a Trade Order')"
                            style="padding:3px 12px;">
                            <img src="/assets/images/trade.png" /><span>&nbsp;Buy/Sell</span>
                        </Button>
                        </div>
                    </div>
                    <div class='actions-container'>
                        <div class="pending-orders-checkbox-container" v-if="isTradingEnabledAccount && overviewOption === 'Orders'">
                            <label for="pending-only-checkbox" class="pending-only-checkbox">Pending Only</label>
                            <Checkbox v-model="pendingOnly" inputId="pending-only-checkbox" :binary="true" inputClass="green-button__primary"/>
                        </div>
                        <SelectButton v-if="transactionData && !isCryptoAccount &&  !isMobile()" :class="overviewToggleClass" :options="overviewTableOptions" v-model="overviewOption" />
                    </div>
                </div>
                
                <template v-if="isMobile()">
                    <div class="holdings-wrapper" >
                    <SelectButton :class="holdingsViewToggleClass" :options="holdingsViewOptions" v-model="holdingsView"  />
                    <SelectButton v-if="transactionData && !isCryptoAccount " :class="overviewToggleClass" :options="overviewTableOptions" v-model="overviewOption" />
                    </div>
                     <div class='perf-currency-button-mobile' v-if="(holdingsTableOn && (isTeamPortfolio || isAggregatedPortfolio) && holdingsView == 'Performance')">
                        <SelectButton :unselectable="false" v-model="portfolioHoldingsCurrencyId" :options="currencyOptions" optionLabel="name" optionValue="value" @change="changedPortfolioHoldingsCurrencyId"/>
                    </div>
                </template>
                <div class='perf-currency-button' v-if="(holdingsTableOn && (isTeamPortfolio || isAggregatedPortfolio) && holdingsView == 'Performance' && !isMobile())">
                    <SelectButton :unselectable="false" v-model="portfolioHoldingsCurrencyId" :options="currencyOptions" optionLabel="name" optionValue="value" @change="changedPortfolioHoldingsCurrencyId"/>
                </div>

                    <HoldingsTable :holdings="holdings" v-if="holdingsTableOn" :holdingsView="holdingsView" :selectedAccount="$store.getters['currentPortfolio']" @update-position="openPositionInputModal"/>
                    
                    <TransactionsTable v-else-if="transactionsTableOn" :transactions="transactions" :totalTransactions="totalTransactions" :limitPerPage="limitPerPage" :loadingTransactions="loadingOverviewTableData" />

                    <OrdersTable v-else :orders="orders" :limitPerPage="limitPerPage" :orderTotal="orderTotal" :loadingOrders="loadingOverviewTableData" :pendingOrdersOnly="pendingOnly" 
                    @cancelled-order="cancelledOrderCallback" @load-more-orders="loadMoreOrdersCallback" :showDelay="isCompetitionAccount"/>
                <AccountTransferModal @complete-transfer="onCompleteTransfer" ref="accountTransferModal" :selectedGroup="selectedGroup"/>
            
            </div>
        </div>
        <PositionInputModal ref='positionInputModal'/>
        <CashTransactionModal ref='cashTransactionModal'/>
        <BuyPlayDoughModal @wad-selected="onWadSelected" ref="buyPlayDoughModal"/>
        <BuyPlaydoughViaWebsiteModal ref="buyPlaydoughViaWebsiteModal"/>
        <ConfirmModal ref="confirmResetModal" @confirmed="onResetConfirmed" @cancelled="onResetCancelled" headerText="Confirm Competition Reset" :confirmButtonGreen="true">
            <template #content>
                <div class="reset-confirm-container">
                    <p>Resetting your competition investment account will delete your current holdings and issue a new competition account.</p>
                    <p>There is a fee of $4.95 USD to perform this action.</p>
                    <p>Are you sure you want to reset your competition account?</p>
                </div>        
            </template>
        </ConfirmModal>
    </div>
</template>

<script>
import Checkbox from 'primevue/checkbox';
import AccountTransferModal from './modal/AccountTransferModal.vue';
import PerformanceChart from './overview/PerformanceChart.vue';
import AllocationChart from './overview/AllocationChart.vue';
import HoldingsTable from './overview/HoldingsTable.vue';
import TopPerformersTable from './overview/TopPerformersTable.vue';
import TransactionsTable from './overview/TransactionsTable.vue';
import OrdersTable from './overview/OrdersTable.vue';
import PortfolioService from '../../service/PortfolioService';
import DataService from '../../service/DataService';
import TradingService from '../../service/TradingService';
import LoadingSpinner from '../../components/common/LoadingSpinner.vue';
import PositionInputModal from './modal/PositionInputModal.vue';
import CashTransactionModal from './modal/CashTransactionModal.vue';
import SelectButton from 'primevue/selectbutton';
import EventBus from '../../event-bus';
import BuyPlayDoughModal from './modal/BuyPlayDoughModal.vue';
import BuyPlaydoughViaWebsiteModal from './modal/BuyPlaydoughViaWebsiteModal.vue';

import { getTotalCash, getDataFromOrders } from './';
import format from 'date-fns/format';
//import InputSwitch from 'primevue/inputswitch';
import { PAPER_ANALYSIS_ACCOUNT_TYPE, PAPER_TRADING_ACCOUNT_TYPE, CRYPTO_ACCOUNT_TYPE, ACCOUNT_TYPES, 
    CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE, DEFAULT_PORTFOLIO_SUMMARY_CURRENCY,PAPER_CASH_ACCOUNT_TYPE, COMPETITION_ACCOUNT_TYPE, RESET_COMPETITION_PRODUCT_ID,
    EDUCATIONAL_ACCOUNT_TYPE} from '../../common/constants';
import SecurityFormatter from '../../common/SecurityFormatter';
import BrowserUtils from '../../utilities/BrowserUtils';
import FormattingUtils from '../../utilities/FormattingUtils';
import TimeUtils from '../../utilities/TimeUtils';
import UserUtils from '../../utilities/UserUtils';
import UserService from '../../service/UserService';
import PaymentService from '../../service/PaymentService';
import ConfirmModal from '../modal/ConfirmModal.vue';

const CDN_EXCHANGES = ['XTSE', 'XTSX', 'XCNQ', 'XTNX'];
const COMP_SYMBOLS = ['SPY', 'XIC:CA'];

export default {
    name: 'Portfolio-Overview',
    emits: ['reload-account'],
    components: {
    PerformanceChart,
    AllocationChart,
    HoldingsTable,
    TransactionsTable,
    OrdersTable,
    LoadingSpinner,
    SelectButton,
    TopPerformersTable,
    AccountTransferModal,
    PositionInputModal,
    CashTransactionModal,
    Checkbox,
    BuyPlayDoughModal,
    BuyPlaydoughViaWebsiteModal,
    ConfirmModal
},

    props: {
        selectedAccountData: {
            required: false,
        },

        fullLoad: {
            default: false
        },

        selectedGroup: {
            required: false,
            default: undefined
        },
    },

    data() {
        return {
            //CONSTANTS
            PAPER_ANALYSIS_ACCOUNT_TYPE,
            PAPER_TRADING_ACCOUNT_TYPE,
            PAPER_CASH_ACCOUNT_TYPE,
            COMPETITION_ACCOUNT_TYPE,
            EDUCATIONAL_ACCOUNT_TYPE,

            formatter: null,

            pendingOnly: false,
            txnsLoaded: false,
            holdingsLoaded: false,
            allocationLoaded: false,
            perfLoaded: false,
            ordersLoaded: false,
            accountTypeId: null,
            totalMarketValue: null,
            totalReturnAmount: null,
            totalReturnPercent: null,
            percentReturnSeries: [],
            returnDateSeries: [],
            comparisonReturnSeries: [],
            sectorLabels: [],
            sectorValues: [],
            assetLabels: [],
            assetValues: [],
            holdingLabels: [],
            holdingValues: [],
            holdings: [],
            transactions: [],
            orders: [],
            returnStartDate: null,
            returnEndDate: null,
            comparisonSymbol: 'SPY',
            //overviewTableOptions: [],
            overviewOption: 'Holdings',
            limitPerPage: 25,
            currentOffset: 0,
            totalTransactions: null,
            orderTotal: null,
            loadingOverviewTableData: false,
            errorLoading: false,
         
            quoteFetcher: null,
            holdingsViewOptions: ['Trading', 'Performance'],
            holdingsView: 'Performance',
            performers: [],
            cadToUsd: null,
            usdToCad: null,
            portfolioHoldingsCurrencyId: 1,
            currencyOptions: [{name: 'CAD', value: 1}, {name: 'USD', value:2}],
            
        };
    },

    created() {
        this.formatter = new FormattingUtils();

        EventBus.off('refresh-portfolio-overview');
        EventBus.on('refresh-portfolio-overview', this.loadPortfolioData);
        
        EventBus.off('update-group-portfolio-holdings-currency');
        EventBus.on('update-group-portfolio-holdings-currency', ({groupPortfolioId, defaultCurrencyId}) => {
            if(this.selectedGroup 
                && this.selectedAccountData.groupPortfolioId == groupPortfolioId 
                && this.portfolioHoldingsCurrencyId != defaultCurrencyId) {

                this.portfolioHoldingsCurrencyId = defaultCurrencyId;
                this.getGroupPortfolioHoldings();
            }
        });

        EventBus.off('transactions-page', this.loadMoreTransactions);
        EventBus.on('transactions-page', this.loadMoreTransactions);

        EventBus.off('open-motion-from-existing-position', this.triggerMotionModalForExistingPosition);
        EventBus.on('open-motion-from-existing-position', this.triggerMotionModalForExistingPosition);

    },
    mounted() {
         this.initializeOverview();
    },

    activated() {
        console.log("activating portfolio Overview")
        //this.initializePricePolling();
    },

    deactivated() {
        console.log("deactivating portfolio overview");
        this.stopPollingPriceChange();
    },
    computed: {
        comparisonIndex() {
            if( this.selectedAccountData?.currencyId == 1) {
                return "TSX";
            }
            else {
                return 'S&P 500';
            }
        },
        recentlyViewedPortfolio() {
            return this.$store.getters['recentPortfolio'];
        },

        portfolioHoldingsData() {
            return this.$store.getters['portfolioHoldingsData'];
        },

        portfolioAllocationData() {
            return this.$store.getters['portfolioAllocationData'];
        },

        holdingsAllocationData() {
            return this.$store.getters['holdingsAllocationData'];
        },

        portfolioPerformanceData() {
            return this.$store.getters['portfolioPerformanceData'];
        },

        comparisonPerformanceData() {
            return this.$store.getters['comparisonPerformanceData'];
        },

        transactionData() {
            return this.$store.getters['transactionData'];
        },

        ordersData() {
            return this.$store.getters['ordersData'];
        },

        hasRecentlyViewedPortfolio() {
            return this.comparisonPerformanceData && this.portfolioPerformanceData && this.portfolioHoldingsData && this.transactionData && this.recentlyViewedPortfolio
                && this.recentlyViewedPortfolio.portfolioName && !this.recentlyViewedPortfolio.portfolioData.groupPortfolioId;
        },

        holdingsTableOn() {
            return this.overviewOption === 'Holdings';
        },

        transactionsTableOn() {
            return this.overviewOption === 'Transactions';
        },
        ordersTableOn() {
            return this.overviewOption === 'Orders';
        },

        isCryptoAccount() {
            return this.accountTypeId === CRYPTO_ACCOUNT_TYPE['ID'];
        },

        isPaperAccount() {
            return this.isPaperAnalysisAccount || this.isPaperTradingAccount;
        },

        isPaperAnalysisAccount() {
            return this.accountTypeId === PAPER_ANALYSIS_ACCOUNT_TYPE['ID']
        },

        isPaperTradingAccount() {
            return this.accountTypeId === PAPER_TRADING_ACCOUNT_TYPE['ID'];
        },

        isCompetitionAccount() {
            return this.accountTypeId && this.accountTypeId === COMPETITION_ACCOUNT_TYPE['ID'];
        },

        isEducationalAccount() {
             return this.accountTypeId && this.accountTypeId === EDUCATIONAL_ACCOUNT_TYPE['ID'];
        },

        isTradingEnabledAccount() {
            if( this.selectedAccountData ) {
                return [PAPER_TRADING_ACCOUNT_TYPE['ID'], COMPETITION_ACCOUNT_TYPE['ID'], EDUCATIONAL_ACCOUNT_TYPE['ID']].includes(this.selectedAccountData.accountTypeId);
            }
            else {
                return false;
            }
        },

        competitionResetsAllowed() {
            
            return this.isCompetitionAccount && this.selectedAccountData?.contest?.resetsAllowed && !this.selectedAccountData.archiveDate;
        },

        isPlacingTradesAccount() {
            return this.isPaperTradingAccount || this.isCompetitionAccount || this.isEducationalAccount;
        },

       holdingsViewToggleClass() {
            if( this.overviewOption === 'Transactions' ) {
                return 'holdings-view-toggle toggle-disabled';
            }
            else {
                return 'holdings-view-toggle';
            }
            
       },

       overviewToggleClass() {
            if( this.selectedAccountData  && this.selectedAccountData.investmentAccountId == -999) {//community portfolio disable txns
                return 'overview-table-toggle toggle-disabled';
            }
            else {
                return 'overview-table-toggle';
            }
       },

        isCommunityPortfolio() {
            if( this.selectedAccountData ){
                return this.selectedAccountData.investmentAccountId == -999;
            }
            else {
                return false;
            }
        },

        isTeamPortfolio() {
             if( this.selectedAccountData ){
                return this.selectedAccountData.accountTypeId == -1;
            }
            else {
                return false;
            }
        },

        isAggregatedPortfolio() {
            if( this.selectedAccountData ){
                return this.selectedAccountData.investmentAccountId == -998;
            }
            else {
                return false;
            }
        },

       

        portfolioLoaded() {
            const baseCondition = !this.accountStillInImport && this.txnsLoaded && this.perfLoaded && this.holdingsLoaded && this.allocationLoaded;

            return this.selectedAccountData?.accountTypeId === PAPER_TRADING_ACCOUNT_TYPE['ID'] ? baseCondition && this.ordersLoaded : baseCondition;
        },

        groupPortfolioLoaded() {
            // console.log("groupPortfolioLoaded", this.selectedGroup, this.perfLoaded, this.holdingsLoaded, this.allocationLoaded);
            return this.selectedGroup && this.perfLoaded && this.holdingsLoaded && this.allocationLoaded;
        },

        accountStillInImport() {
            if (this.selectedAccountData && this.selectedAccountData.lastSyncStatus) {
                const status  = this.selectedAccountData.lastSyncStatus;

                return status.indexOf('waiting') >= 0 || status.indexOf('Importing') >= 0;
            }

            return false;
        },

        holdingsDescription() {
            if( this.isCommunityPortfolio) {
                return 'The top 10 holdings across all portfolios.';
                
            }else if( this.isAggregatedPortfolio) {
                return 'All of the non-cash positions across all of your investment accounts.';
            }
            else {
                return 'All of the non-cash positions in your investment account.';

                
             }
        },

        totalValue() {
            return this.selectedAccountData.totalValue;
        },

        totalCashValue() {
            return this.selectedAccountData?.cashPositions ? getTotalCash(this.selectedAccountData?.cashPositions) : 0;
        },

        overviewTableOptions() {
            if( this.accountTypeId && this.accountTypeId > 0) {
                if (this.isPlacingTradesAccount) {
                    return ['Holdings', 'Orders', 'Transactions'];

                }
                else if (this.isPaperAnalysisAccount) {
                    return ['Holdings', 'Orders'];

                } else { // paper cash account or connected accounts
                
                    return ['Holdings', 'Transactions'];
                }
            }
            else {
                return [];
            }
        }

    },

    watch: {
        selectedAccountData: {
            handler(value) {
                if (!value) return;

                try {
                    // console.log(8);
                    //this.stopPollingPriceChange();
                    //this.holdings = [];
                    this.initializeOverview();
                    this.holdingsView = 'Performance';
                } catch (err) {
                    console.log(err);
                }
            },
            deep: true
        },

        portfolioLoaded(value) {
            if (value) {
                EventBus.emit('loaded-overview');
                //this.initializePricePolling("Portfolio Loaded");
            }
        },

        groupPortfolioLoaded(value) {
            if (value) {
                EventBus.emit('loaded-overview');
                //this.initializePricePolling("Group Portfolio Loaded");
            }
        },

        holdingsLoaded(value) {
            if( value) {
                this.initializePricePolling("Holdings Loaded");
            }
            else {
                this.stopPollingPriceChange();
            }
        },
        

        overviewOption() {
            this.currentOffset = 0;
        },

        pendingOnly(val) {
            this.currentOffset = 0;
            this.getOrdersData(this.selectedAccountData.investmentAccountId, this.limitPerPage, this.currentOffset, val);
        },
    },

   

    methods: {

        onResetCompetitionAccount() {
            this.$refs.confirmResetModal.open();
        },

        onResetConfirmed() {
            let req = {investmentAccountId: this.selectedAccountData.investmentAccountId, productId: RESET_COMPETITION_PRODUCT_ID}
            PaymentService.createCompetitionResetCheckoutSession(req).then(resp => {
                if (resp.data.status === 'success') {
                    window.location.href = resp.data.url;
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                }
            }).catch(err => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: err.message, life: 3000,  group: 'center'  });
            });
            
        },

        onResetCancelled() {

        },

        isGroupOwnerOrAdmin() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId) || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },
        showTransferButton(){
            if(this.isPaperTradingAccount || this.isEducationalAccount) { // paper accounts only
                if( this.isOwnAccount() ){
                    return true;
                }
                else if( this.selectedGroup ) { // looking at group portfolio and...

                    // u are admin or owner of group and account is part of collaborative portfolio
                    return ( this.selectedAccountData.collaborative && this.isGroupOwnerOrAdmin() );
                }
                else {
                    return false;
                }
            } else {
                return false;
            }
        },

        showBuyPlaydoughButton() {
            if( this.isPaperTradingAccount || this.isEducationalAccount ) {// paper accounts only
                if( this.isOwnAccount() ){
                    return true;
                }
                else if( this.selectedGroup ) { // looking at group portfolio and...

                    // u are admin or owner of group and account is part of collaborative portfolio
                    return ( this.selectedAccountData.collaborative && this.isGroupOwnerOrAdmin() );
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },

        buyPlaydough() {
            if(BrowserUtils.isNativeApp()) {
                this.$refs.buyPlaydoughViaWebsiteModal.open();
            } else {
                this.$refs.buyPlayDoughModal.open(this.selectedAccountData);
            }
        },

        onWadSelected( wadSelection ) {
            if( this.selectedGroup ) {
                 PaymentService.createGroupPlayDoughCheckoutSession(this.selectedGroup.groupId, wadSelection).then(resp => {
                    if (resp.data.status === 'success') {
                        window.location.href = resp.data.url;
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                    }
                }).catch(err => {
                    console.log(err);
                    this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: err.message, life: 3000,  group: 'center'  });
                });
            }
            else {
                PaymentService.createPlayDoughCheckoutSession(wadSelection).then(resp => {
                    if (resp.data.status === 'success') {
                        window.location.href = resp.data.url;
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                    }
                }).catch(err => {
                    console.log(err);
                    this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: err.message, life: 3000,  group: 'center'  });
                });
            }
        },

        // pageTransactions(event) {
        //     console.log("Received page transactions event " + JSON.stringify(event));
        //     this.loadMoreTransactions(event);
        // },

        canShowDollarAmount() {
           
            // for down the road we will also be checking setting on group portfolio
            // but for now just check that the account we are viewing is the current user's or the account is a collaborative account
            return this.isOwnAccount() || this.selectedAccountData.collaborative;
        },

        isOwnAccount() {
            if( this.selectedAccountData && this.selectedAccountData.userId == this.$store.state.users.user.userId ) {
                return true;
            }
            else {
                return false;
            }
        },
        triggerMotionModalForExistingPosition(data) { // eslint-disable-line
            // todo pass data which should have security in it to motion screen
            EventBus.emit('open-create-motion-modal', {group:this.selectedGroup, accountId: this.selectedAccountData.investmentAccountId, security: data.security});
        },

        onTradingButtonClick() {
            if( this.selectedAccountData.collaborative) {
               
                EventBus.emit('open-create-motion-modal', {group:this.selectedGroup, accountId: this.selectedAccountData.investmentAccountId });
            }
            else {

                EventBus.emit('open-trading-paper-accounts-modal');
            }
        },
        startTransfer() {//eslint-disable-line
            this.$refs.accountTransferModal.open(this.selectedAccountData);
        },
        onCompleteTransfer(transfer) {

            if( transfer.groupId ) {

                PortfolioService.recordGroupAccountTransfer(transfer).then(resp => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'The transfer completed successfully.', detail: resp.message, life: 3000 ,  group: 'center' });
                        this.$emit('reload-account');
                        this.loadPortfolioData();
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error transfering funds. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                    }
                });
            }
            else {
                PortfolioService.transferFunds(transfer).then(resp => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'The transfer completed successfully.', detail: resp.message, life: 3000 ,  group: 'center' });
                        this.loadPortfolioData();
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error transfering funds. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                    }
                });
            }
        },

       isMobile() {
        return BrowserUtils.isMobile();
       },

        initializePricePolling(context) { //eslint-disable-line
            //console.log("initialize price polling called from " + context);
            //if( this.holdingsView == 'Trading') {

                
                // start getting trading snap quotes
                this.getSnapQuotes();
                this.startPollingPriceChange();
            //}
            //else {
                // turn it off
                
                //this.stopPollingPriceChange() ;
            //}
        },

        startPollingPriceChange() {
			if( this.quoteFetcher ){
				clearInterval(this.quoteFetcher);
			}
			this.quoteFetcher = setInterval(()=> {
				this.getSnapQuotes();
			}, 10000);
		},
		stopPollingPriceChange() {
			if( this.quoteFetcher ){
				clearInterval(this.quoteFetcher);
			}
		},

        createSnapQuoteSymbols() {
            let symbols = [];
            this.holdings.forEach( h => {
                let symbol = this.createSnapQuoteSymbol(h);
                if( symbol ) {
                    symbols.push(symbol);
                }
            });

            return symbols;
            
        },

        createSnapQuoteSymbol(h) {
            let symbol = null;
            let exSymbol = null;
            if( h.otherAsset ) {
                symbol =  h.otherAsset?.symbol ;
                exSymbol = h.otherAsset?.exchangeSymbol;
            } else if( h.securityListing ) {
                symbol =  h.securityListing?.symbol 
                exSymbol = h.securityListing?.exchangeSymbol; 
            } else if( h.fund ) {
                symbol =  h.fund?.symbol 
                exSymbol = h.fund?.exchangeSymbol; 
            }
           
            if( symbol ) {
                return symbol+SecurityFormatter.getQMExchangeSymbolSuffix(exSymbol);
            }
        },

        isCDNHolding(el) {
            if( el.securityListing ) {
                return CDN_EXCHANGES.includes(el.securityListing.exchangeSymbol );
            }
            else if( el.fund ) {
                return CDN_EXCHANGES.includes(el.fund.exchangeSymbol );
            }
            else if( el.derivative) {
                return el.derivative.currencyId == 1;
            }
            else if( el.fixedIncomeSecurity){
                return el.fixedIncomeSecurity.currencyId == 1;
            }
            else {
                return CDN_EXCHANGES.includes(el.otherAsset.exchangeSymbol );
            }
        },

        getMarketValue(el, q) {
            if( this.isTeamPortfolio || this.isAggregatedPortfolio ) {
                // convert to default currency (right now cdn eventually will have toggle)
                if( this.portfolioHoldingsCurrencyId == 1) {
                    if( this.isCDNHolding(el )) {
                        return q.pricedata.last*el.quantity;
                    }
                    else {
                        return (this.usdToCad.rate*q.pricedata.last)*el.quantity;
                    }
                }
                else {
                    if( this.isCDNHolding(el )) {
                        return (this.cadToUsd.rate*q.pricedata.last)*el.quantity;
                    }
                    else {
                        return q.pricedata.last*el.quantity;
                    }
                }
            }
            else {
                return q.pricedata.last*el.quantity;
            }
        },
        async getSnapQuotes() {
            if( this.holdings.length > 0 ) {
                await DataService.getSnapQuotes(this.createSnapQuoteSymbols()).then(resp => {
                        
                    let quoteData = resp.data.results.quote;
                    if( quoteData) {
                        this.holdings.forEach( el => {
                            //console.log("is cdn holding = " + this.isCDNHolding(el))
                            quoteData.forEach( q => {
                              if (q.pricedata) {
                                if( q.key.symbol === this.createSnapQuoteSymbol(el)) {
                                   
                                    el['changepercent'] = q.pricedata.changepercent;
                                    el['open'] = q.pricedata.open;
                                    el['close'] = q.pricedata.close;
                                    el['last'] = q.pricedata.last;
                                    el['marketValue'] = this.getMarketValue(el, q);//q.pricedata.last*el.quantity;
                                    el['high'] = q.pricedata.high;
                                    el['low'] = q.pricedata.low;
                                    el['bid'] = q.pricedata.bid;
                                    el['ask'] = q.pricedata.ask;
                                    el['tradevolume'] = q.pricedata.tradevolume;
                                    el['prevclose'] = q.pricedata.prevclose;
                                    el['tick'] = q.pricedata.tick;
                                    if( q.fundamental && q.fundamental.dividend) {
                                        el['yield'] = q.fundamental.dividend.yield;
                                    }
                                    else {
                                        el['yield'] = '-';
                                    }
                                    
                                }
                              }
                            });
                        });
                    }

                    this.loading = false;
                });
            }
            else {
                this.loading = false;
            }
        },

        initializeOverview() {
            //console.log("initializeOverview() called");
            PortfolioService.getLatestForexRates().then( resp => {
                
                if( resp.data.status == 'success') {
                    this.usdToCad = resp.data.usdToCad;
                    this.cadToUsd = resp.data.cadToUsd;
                }
            });

            if( this.selectedGroup ) {
                this.portfolioHoldingsCurrencyId = this.selectedAccountData?.defaultCurrencyId;
            } else if( !this.selectedGroup ) {
                this.portfolioHoldingsCurrencyId = this.$store.getters['users/getSetting'](DEFAULT_PORTFOLIO_SUMMARY_CURRENCY)?.value == CANADIAN_CURRENCY_TYPE.SYMBOL 
                    ? CANADIAN_CURRENCY_TYPE.ID : US_CURRENCY_TYPE.ID;
            }

            if (this.fullLoad || this.selectedGroup) {
                // console.log('full overview load')
                this.loadPortfolioData();
                return;
            } else if (this.hasRecentlyViewedPortfolio) {
                // console.log('loading portfolio overview from store');
                this.loadPortfolioDataFromStore();
            
            } else if( this.selectedAccountData ) { // && !this.accountStillInImport) {
                // console.log(9);
                // console.log('loading portfolio overview from db');
                this.loadPortfolioData();
            }
        },

        getSelectedAccountData() {
            this.totalMarketValue = this.selectedAccountData.totalValue;
            this.totalReturnAmount = this.selectedAccountData.cumReturnAmount;
            this.totalReturnPercent = this.selectedAccountData.cumReturn * 100;
            this.accountTypeId = this.selectedAccountData.accountTypeId;

            
        },

        changedPortfolioHoldingsCurrencyId(){
            const currencySymbol = this.portfolioHoldingsCurrencyId == CANADIAN_CURRENCY_TYPE.ID ? CANADIAN_CURRENCY_TYPE.SYMBOL : US_CURRENCY_TYPE.SYMBOL;

            if( this.selectedGroup ) {
                this.getGroupPortfolioHoldings();
            } else {
                this.getPortfolioHoldingsData(this.selectedAccountData.investmentAccountId, this.portfolioHoldingsCurrencyId);

                UserService.updateUserPreference( DEFAULT_PORTFOLIO_SUMMARY_CURRENCY, currencySymbol ).then((res) => {
                    if (res.data.status === 'error') {
                        // this.$toast.add({ severity: 'error', summary: res.data.message, life: 3000, group: 'center' });
                    } else {
                        const obj = {
                            'id': DEFAULT_PORTFOLIO_SUMMARY_CURRENCY,
                            'value': currencySymbol
                        };

                        this.$store.dispatch("users/setSetting", obj);
                        // console.log(this.$store.getters['users/settingsList']);
                        // this.$toast.add({severity: 'success', summary: 'Updated default currency for your portfolio holdings!', life: 3000, group: 'center'});
                    }
                })
                .catch((err) => {
                    console.error(err);
                    // this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                })
            }

        },

        currencyValueProcessor(currency) {
            if (currency === CANADIAN_CURRENCY_TYPE['ID']) {
                return 'Canadian Dollar';
            } else {
                return 'US Dollar';
            }
        },

        accountTypeProcessor(typeId) {
            return ACCOUNT_TYPES[typeId];
        },

        // populateOverviewTableToggleButtons() {
        //     if (this.accountTypeId === PAPER_TRADING_ACCOUNT_TYPE['ID']) {
        //         this.overviewTableOptions = ['Holdings', 'Orders', 'Transactions'];

        //     }
        //     else if (this.accountTypeId === PAPER_ANALYSIS_ACCOUNT_TYPE['ID']) {
        //         this.overviewTableOptions = ['Holdings', 'Orders'];
        //     } else {
        //         this.overviewTableOptions = ['Holdings', 'Transactions'];
        //     }
        // },

        resetLoadedState(val){
            this.txnsLoaded = val;
            this.perfLoaded = val;
            this.holdingsLoaded = val;
            this.allocationLoaded = val;
            this.ordersLoaded = val;
        },

        loadPortfolioDataFromStore() {
            //console.log('loading overview from store');
            //this.portfolioLoaded = false;
            this.resetLoadedState(false);

            const { portfolioData } = this.recentlyViewedPortfolio;
            const { totalValue, cumReturnAmount, cumReturn, accountTypeId } = portfolioData;
            this.totalMarketValue = totalValue;
            this.totalReturnAmount = cumReturnAmount;
            this.totalReturnPercent = cumReturn * 100;
            this.accountTypeId = accountTypeId;

            const { percentReturns, returnDates } = this.portfolioPerformanceData;
            this.percentReturnSeries = percentReturns;
            this.returnDateSeries = returnDates;

            this.comparisonReturnSeries = this.comparisonPerformanceData;

            const { holdingLabels, holdingValues } = this.holdingsAllocationData;
            this.holdingLabels = holdingLabels;
            this.holdingValues = holdingValues;

            const { sectorLabels, sectorValues, assetLabels, assetValues } = this.portfolioAllocationData;
            this.sectorLabels = sectorLabels;
            this.sectorValues = sectorValues;
            this.assetLabels = assetLabels;
            this.assetValues = assetValues;

            this.holdings =  this.$store.state.portfolioHoldingsData;//.getters['portfolioHoldingsData'];//this.portfolioHoldingsData;
            // if( this.isMobile() ) {
            //     this.performers = this.$store.state.topPerformers;
            // }

            const { totalTransactions, transactions } = this.transactionData;
            this.transactions = transactions;
            this.totalTransactions = totalTransactions;

            if (this.isPaperAccount || this.isCompetitionAccount || this.isEducationalAccount) {
                if( this.ordersData ) {
                    const { orderTotal, orders } = this.ordersData;
                    this.orderTotal = orderTotal;
                    this.orders = orders;
                }
            }

            //this.populateOverviewTableToggleButtons();

            this.overviewOption = this.isCryptoAccount ? 'Transactions' : 'Holdings';

            //this.portfolioLoaded = true;
            this.resetLoadedState(true);
        },

        loadPortfolioData() {
            this.pendingOnly = false;
            // console.log('loading overview via methods');
            try {
                // console.log(10);
                //this.portfolioLoaded = false;
                this.resetLoadedState(false);
                this.getSelectedAccountData();
                //this.populateOverviewTableToggleButtons();

                this.overviewOption = this.isCryptoAccount ? 'Transactions' : 'Holdings';

                // console.log("loadPortfolioData", this.selectedAccountData);

                if(this.selectedGroup && this.selectedAccountData.investmentAccountId && this.selectedAccountData.groupPortfolioId){
                    this.getGroupPortfolioMemberBreakdown();
                    this.getGroupPortfolioMemberHoldings();
                    this.getGroupPortfolioMemberReturns();
                    this.getTransactionsData(this.selectedAccountData.investmentAccountId, this.limitPerPage, this.currentOffset);
                    
                    if (this.isPaperAccount || this.isCompetitionAccount || this.isEducationalAccount) {
                        this.getOrdersData(this.selectedAccountData.investmentAccountId, this.limitPerPage, this.currentOffset, false);
                    }

                    this.$store.commit('SET_RECENTLY_VIEWED_PORTFOLIO', null);
                } else if(this.selectedGroup && this.selectedAccountData.groupPortfolioId) {
                    this.getGroupPortfolioBreakdown();
                    this.getGroupPortfolioHoldings();
                    this.getGroupPortfolioReturns();

                    this.$store.commit('SET_RECENTLY_VIEWED_PORTFOLIO', null);
                } else if(!this.selectedGroup && this.selectedAccountData.investmentAccountId) {
                    this.getPortfolioAllocationData(this.selectedAccountData.investmentAccountId);
                    this.getPortfolioHoldingsData(this.selectedAccountData.investmentAccountId, this.portfolioHoldingsCurrencyId);
                //this.getTopPerformersData(this.selectedAccountData.investmentAccountId);
                    this.getPortfolioPerformance(this.selectedAccountData);
                    this.getTransactionsData(this.selectedAccountData.investmentAccountId, this.limitPerPage, this.currentOffset);

                    if (this.isPaperAccount || this.isCompetitionAccount|| this.isEducationalAccount) {
                        this.getOrdersData(this.selectedAccountData.investmentAccountId, this.limitPerPage, this.currentOffset, false);
                    }

                    this.$store.commit('SET_RECENTLY_VIEWED_PORTFOLIO',   {
                        portfolioData: this.selectedAccountData,
                        portfolioName: this.selectedAccountData.fullName,
                    });
                }
                
            } catch (err) {
                console.log("loadPortfolioData", err);
                this.errorLoading = true;
                //this.$toast.add({ severity: 'error', summary: 'Error loading portfolio data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
            }
        },

        getPortfolioAllocationData(accountId) {
            // console.log("getPortfolioAllocationData", this.selectedAccountData, accountId);
            PortfolioService.getPortfolioAllocationData(accountId).then(allocationData=> {
                if (allocationData.status === 200) {
                    this.getDataFromPortfolioAllocation(allocationData.data);
                    //console.log("getPortfolioAllocationData", this.selectedAccountData, allocationData.data);
                }
                this.allocationLoaded = true;
            });
        },

        getGroupPortfolioBreakdown() {
            PortfolioService.getGroupPortfolioBreakdown(this.selectedAccountData.groupPortfolioId).then(allocationData=> {
                if (allocationData.status === 200) {
                    this.getDataFromPortfolioAllocation(allocationData.data);
                    // console.log("getGroupPortfolioBreakdown", this.selectedAccountData, allocationData.data);
                }
                this.allocationLoaded = true;
            });
        },

        getGroupPortfolioMemberBreakdown() {
            PortfolioService.getGroupPortfolioMemberBreakdown(this.selectedAccountData.groupPortfolioId, 
                this.selectedAccountData.investmentAccountId).then(allocationData=> {
                if (allocationData.status === 200) {
                    this.getDataFromPortfolioAllocation(allocationData.data);
                    // console.log("getGroupPortfolioMemberBreakdown", this.selectedAccountData, allocationData.data);
                }
                this.allocationLoaded = true;
            });
        },

        getDataFromPortfolioAllocation(allocationData) {
            this.sectorLabels = [];
            this.sectorValues = [];
            this.assetLabels = [];
            this.assetValues = [];

            if (allocationData.status === 'error') {
                //this.$toast.add({ severity: 'error', summary: 'Error getting portfolio allocation data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
                this.$store.commit('SET_PORTFOLIO_ALLOCATION_DATA', {
                        sectorLabels: this.sectorLabels,
                        sectorValues: this.sectorValues,
                        assetLabels: this.assetLabels,
                        assetValues: this.assetValues,
                    },);
                
                // dispatch({
                //     type: 'setPortfolioAllocationData',
                //     payload: {
                //         sectorLabels: this.sectorLabels,
                //         sectorValues: this.sectorValues,
                //         assetLabels: this.assetLabels,
                //         assetValues: this.assetValues,
                //     },
                // });
                return;
            }

            const sectorData = allocationData.sectorMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });
            const assetData = allocationData.classMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });

            const labelValueGenerator = (data, sectorOrAsset) => {
                let formattedCategory = '';
                const splitCategory = data.category.split(' ');
                const formattedWords = splitCategory.map((word) => {
                    return word.replace(word[0], word[0].toUpperCase());
                });

                if (formattedWords.length > 1) {
                    formattedCategory = formattedWords.join(' ');
                } else {
                    formattedCategory = formattedWords[0];
                }

                if (sectorOrAsset === 'sector') {
                    this.sectorLabels.push(formattedCategory);
                    this.sectorValues.push((data.value * 100).toFixed(2));
                } else if (sectorOrAsset === 'asset') {
                    this.assetLabels.push(formattedCategory);
                    this.assetValues.push((data.value * 100).toFixed(2));
                }
            };

            sectorData.forEach((data) => {
                labelValueGenerator(data, 'sector');
            });

            assetData.forEach((data) => {
                labelValueGenerator(data, 'asset');
            });

            // console.log("SET_PORTFOLIO_ALLOCATION_DATA", {
            //         sectorLabels: this.sectorLabels,
            //         sectorValues: this.sectorValues,
            //         assetLabels: this.assetLabels,
            //         assetValues: this.assetValues,
            //     });

            this.$store.commit('SET_PORTFOLIO_ALLOCATION_DATA', {
                    sectorLabels: this.sectorLabels,
                    sectorValues: this.sectorValues,
                    assetLabels: this.assetLabels,
                    assetValues: this.assetValues,
                });

            // this.$store.dispatch({
            //     type: 'setPortfolioAllocationData',
            //     payload: {
            //         sectorLabels: this.sectorLabels,
            //         sectorValues: this.sectorValues,
            //         assetLabels: this.assetLabels,
            //         assetValues: this.assetValues,
            //     },
            // });
        },

        getPortfolioHoldingsData(accountId) {
            PortfolioService.getPortfolioHoldingsData(accountId, this.portfolioHoldingsCurrencyId).then(assetPositionData=>{
                if (assetPositionData.status === 200) {
                    this.getDataFromPortfolioHoldings(assetPositionData.data);
                }
                this.holdingsLoaded = true;
            });
        },

        getGroupPortfolioHoldings() {
            PortfolioService.getGroupPortfolioHoldings(this.selectedAccountData.groupPortfolioId,this.portfolioHoldingsCurrencyId).then(assetPositionData=>{
                if (assetPositionData.status === 200) {
                    this.getDataFromPortfolioHoldings(assetPositionData.data);
                }
                this.holdingsLoaded = true;
            });
        },

        getGroupPortfolioMemberHoldings() {
            PortfolioService.getGroupPortfolioMemberHoldings(this.selectedAccountData.groupPortfolioId, 
                this.selectedAccountData.investmentAccountId).then(assetPositionData=>{
                if (assetPositionData.status === 200) {
                    this.getDataFromPortfolioHoldings(assetPositionData.data);
                }
                this.holdingsLoaded = true;
            });
        },

        getDataFromPortfolioHoldings(assetPositionData) {
            if (assetPositionData.status === 'error') {
                //this.$toast.add({ severity: 'error', summary: 'Error getting portfolio holdings data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
                this.$store.commit('SET_PORTFOLIO_HOLDINGS_DATA', this.holdings);
            //    this.$store.dispatch({
            //         type: 'setPortfolioHoldingsData',
            //         payload: this.holdings,
            //     });
                return;
            }

            const equityPositions = assetPositionData.holdings.equityPositions;
            const fundPositions = assetPositionData.holdings.fundPositions;
            const derivativePositions = assetPositionData.holdings.derivativePositions;
            const fixedIncomePositions = assetPositionData.holdings.fixedIncomePositions;
            const otherPositions = assetPositionData.holdings.otherAssetPositions;

            const publicHoldingsArray = [];

            if (equityPositions.length > 0) {
                equityPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fundPositions.length > 0) {
                fundPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (derivativePositions.length > 0) {
                derivativePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fixedIncomePositions.length > 0) {
                fixedIncomePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (otherPositions.length > 0) {
                otherPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            const orderedHoldingsArray = publicHoldingsArray.sort((a, b) => {
                return b.weight - a.weight;
            });

            this.holdings = orderedHoldingsArray;

            this.holdingLabels = [];
            this.holdingValues = [];

            let cumulativeWeight = 0;

            this.holdings.forEach(holding => {
                const weight = holding.weight * 100;
                this.holdingValues.push(weight);
                cumulativeWeight += weight;
                
                const ticker = holding.otherAsset?.proxySymbol || holding.otherAsset?.symbol || holding.derivative?.symbol || holding.securityListing?.symbol || holding.fund?.symbol;
                this.holdingLabels.push(ticker);
            });

            this.holdingValues.push(100 - cumulativeWeight);
            this.holdingLabels.push('Other');
            this.$store.commit('SET_HOLDINGS_ALLOCATION_DATA', {
                    holdingLabels: this.holdingLabels,
                    holdingValues: this.holdingValues,
                });

            // this.$store.dispatch({
            //     type: 'setHoldingsAllocationData',
            //     payload: {
            //         holdingLabels: this.holdingLabels,
            //         holdingValues: this.holdingValues,
            //     },
            // });
            this.$store.commit('SET_PORTFOLIO_HOLDINGS_DATA', this.holdings);
            // this.$store.dispatch({
            //     type: 'setPortfolioHoldingsData',
            //     payload: this.holdings,
            // });
        },

        getPortfolioPerformance(accountData) {
            PortfolioService.getPortfolioPerformance(accountData.investmentAccountId).then(portfolioPerformanceSeries=>{
                if (portfolioPerformanceSeries.status === 200) {
                    this.getDataFromPortfolioPerformance(portfolioPerformanceSeries.data);
                    if( this.returnStartDate && this.returnEndDate) {
                        // interval = 0 as its not used, intervalType = 'Y' so we get eod data instead of interday values
                        if( accountData.currencyId == 1) {
                            this.comparisonSymbol = COMP_SYMBOLS[1];
                        }
                        else {
                            this.comparisonSymbol = COMP_SYMBOLS[0];
                        }
                         this.getComparisonPerformance(this.comparisonSymbol, 0, 'Y', format(new Date(this.returnStartDate), 'yyyy-MM-dd'), format(new Date(this.returnEndDate), 'yyyy-MM-dd'));
                    }
                    else {
                        this.perfLoaded = true;
                    }
                   
                
                }
            });
            // console.log(portfolioPerformanceSeries);

            
        },

        getGroupPortfolioReturns() {
            PortfolioService.getGroupPortfolioReturns(this.selectedAccountData.groupPortfolioId).then(portfolioPerformanceSeries=>{
                if (portfolioPerformanceSeries.status === 200) {
                    this.getDataFromPortfolioPerformance(portfolioPerformanceSeries.data);
                    if( this.returnStartDate && this.returnEndDate) {
                        // interval = 0 as its not used, intervalType = 'Y' so we get eod data instead of interday values
                         this.getComparisonPerformance(this.comparisonSymbol, 0, 'Y', format(new Date(this.returnStartDate), 'yyyy-MM-dd'), format(new Date(this.returnEndDate), 'yyyy-MM-dd'));
                    }
                    else {
                        this.perfLoaded = true;
                    }
                   
                
                }
            });
            // console.log(portfolioPerformanceSeries);

            
        },

        getGroupPortfolioMemberReturns() {
            PortfolioService.getGroupPortfolioMemberReturns(this.selectedAccountData.groupPortfolioId, 
                this.selectedAccountData.investmentAccountId).then(portfolioPerformanceSeries=>{
                if (portfolioPerformanceSeries.status === 200) {
                    this.getDataFromPortfolioPerformance(portfolioPerformanceSeries.data);
                    if( this.returnStartDate && this.returnEndDate) {
                        // interval = 0 as its not used, intervalType = 'Y' so we get eod data instead of interday values
                         this.getComparisonPerformance(this.comparisonSymbol, 0, 'Y', format(new Date(this.returnStartDate), 'yyyy-MM-dd'), format(new Date(this.returnEndDate), 'yyyy-MM-dd'));
                    }
                    else {
                        this.perfLoaded = true;
                    }
                   
                
                }
            });
            // console.log(portfolioPerformanceSeries);

            
        },

        getDataFromPortfolioPerformance(portfolioReturnData) {
            // console.log(portfolioReturnData);

            this.percentReturnSeries = [];
            this.returnDateSeries = [];

            if (portfolioReturnData.status === 'error') {
                //this.$toast.add({ severity: 'error', summary: 'Error getting portfolio performance data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
                this.$store.commit('SET_PORTFOLIO_PERFORMANCE_DATA',  {
                        percentReturns: this.percentReturnSeries,
                        returnDates: this.returnDateSeries,
                    });
                
                // this.$store.dispatch({
                //     type: 'setPortfolioPerformanceData',
                //     payload: {
                //         percentReturns: this.percentReturnSeries,
                //         returnDates: this.returnDateSeries,
                //     },
                // });
                return;
            }

            const portfolioPerformanceSeries = portfolioReturnData.dataPoints;
            // need to get out the zero values to make the chart look half normal

            if (portfolioPerformanceSeries.length > 0) {

                const mostRecentPortfolioData = portfolioPerformanceSeries[portfolioPerformanceSeries.length - 1];
                const startingPortfolioData = portfolioPerformanceSeries[0];

                this.returnStartDate = startingPortfolioData.date;
                this.returnEndDate = mostRecentPortfolioData.date;

                portfolioPerformanceSeries.forEach((data) => {
                    this.percentReturnSeries.push(data.value * 100);
                   
                    const formattedDate =  TimeUtils.getFormattedTime(data.date, "MM/DD");
                    this.returnDateSeries.push(formattedDate);
                });
            }

            // console.log('SET_PORTFOLIO_PERFORMANCE_DATA', portfolioReturnData, {
            //             percentReturns: this.percentReturnSeries,
            //             returnDates: this.returnDateSeries,
            //         });

             this.$store.commit('SET_PORTFOLIO_PERFORMANCE_DATA',  {
                        percentReturns: this.percentReturnSeries,
                        returnDates: this.returnDateSeries,
                    });

            // this.$store.dispatch({
            //     type: 'setPortfolioPerformanceData',
            //     payload: {
            //         percentReturns: this.percentReturnSeries,
            //         returnDates: this.returnDateSeries,
            //     },
            // });
        },

        getComparisonPerformance(symbol, interval, intervalType, startDate, endDate) {
            DataService.getPriceHistory(symbol, interval, intervalType, startDate, endDate).then(comparisonPerformance=>{
                 if (comparisonPerformance.status === 200) {
                    this.getDataFromComparisonPerformance(comparisonPerformance.data.results);
                }
                this.perfLoaded = true;
            })
            // console.log(comparisonPerformance);

           
        },

        dateExistsInComparison(aDate, comparisonArray) {
            //"date":"2022-11-18",
           for( var i = 0; i < comparisonArray.length; ++i ) {
                let data = comparisonArray[i];
                const formattedDate = TimeUtils.getFormattedTime(data.date, "MM/DD");
                if( aDate === formattedDate ){
                    return true;
                }
            }
            //console.log(aDate +" doesn't exist in comparison data...removing");
            return false;
        },

        getDataFromComparisonPerformance(comparisonPerformance) {
            // console.log(comparisonPerformance);

            this.comparisonReturnSeries = [];

            const comparisonArray = comparisonPerformance.history[0].eoddata;
            //console.log("comp Array = "+JSON.stringify(comparisonArray));

            const comparisonStartingValue = comparisonArray[comparisonArray.length - 1].close;

            //console.log("comparisonStartingDate "+ comparisonArray[comparisonArray.length - 1].date)

            // The returns may have data points that don't exist in the comparison data series.
            // we let the comparison data series dictate if the datapoint should exist on the chart by removing
            // any data points that don't have a corresponding data point in the comparison data series.
            let toRemove = [];
            for( var i = 0; i < this.returnDateSeries.length; ++i ) {
                if( !this.dateExistsInComparison(this.returnDateSeries[i], comparisonArray) ) {
                    toRemove.push(i);
                }
            }

            let localReturnDateSeries = [...this.returnDateSeries];
            let localPercentReturnSeries = [...this.percentReturnSeries];

            for( var j = 0; j < toRemove.length; ++j ) {
                localReturnDateSeries.splice(toRemove[j]-j, 1);
                localPercentReturnSeries.splice(toRemove[j]-j, 1);
            }

            //console.log("return Date series length after adj: "+ this.returnDateSeries.length);
            //console.log("precent return series length after adj: "+ this.percentReturnSeries.length);
            let localComparisonReturnSeries = [...this.comparisonReturnSeries];
            comparisonArray.forEach((data) => {
                const comparisonReturn = (data.close / comparisonStartingValue - 1) * 100;
                localComparisonReturnSeries.unshift(comparisonReturn);

            });
            this.returnDateSeries = localReturnDateSeries;
            this.percentReturnSeries = localPercentReturnSeries;
            this.comparisonReturnSeries = localComparisonReturnSeries;
            //console.log("comparison return series: "+ JSON.stringify(this.comparisonReturnSeries));
             this.$store.commit('SET_COMPARISON_PERFORMANCE_DATA',   this.comparisonReturnSeries);
            // this.$store.dispatch({
            //     type: 'setComparisonPerformanceData',
            //     payload: this.comparisonReturnSeries,
            // });
        },

        getTransactionsData(accountId, limit, offset, changeStore = true) {   
            PortfolioService.getTransactions(accountId, limit, offset).then(transactionsData => {
                if (transactionsData.status === 200) {
                    this.getDataFromTransactions(transactionsData.data, changeStore);
                    
                    // console.log("getTransactionsData", transactionsData);
                }
                this.txnsLoaded = true;
            });
            

            
        },

        getDataFromTransactions(transactions, changeStore) {
            this.totalTransactions = null;
            this.transactions = [];

            if (transactions.status === 'error') {
                this.loadingOverviewTableData = false;
                //this.$toast.add({ severity: 'error', summary: 'Error getting transaction data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });

                this.$store.commit('SET_TRANSACTION_DATA', {
                        totalTransactions: this.totalTransactions,
                        transactions: this.transactions,
                    }
                );

                // this.$store.dispatch({
                //     type: 'setTransactionData',
                //     payload: {
                //         totalTransactions: this.totalTransactions,
                //         transactions: this.transactions,
                //     },
                // });
            }

            const transactionsArray = transactions.transactions.map((transaction) => {
                const date = new Date(transaction.transactionDate);
                // const year = `${date.getFullYear()}`;
                // const month = `${date.getMonth() + 1}`;
                // const day = `${date.getDate()}`;
                const formattedDate = TimeUtils.getFormattedTime(date, "YYYY/MM/DD HH:mm")

                let description;
                const badDescriptionIndex = transaction.description.indexOf('WE ACTED AS AGENT');
                if (badDescriptionIndex >= 0) {
                    description = transaction.description.slice(0, badDescriptionIndex);
                } else {
                    description = transaction.description;
                }

                return {
                    amount: transaction.amount,
                    currencyCode: transaction.currencyCode,
                    description: description,
                    fee: transaction.fee * -1,
                    quantity: transaction.quantity < 0 ? transaction.quantity * -1 : transaction.quantity,
                    transactionDate: formattedDate,
                    transactionType: transaction.transactionType[0].toUpperCase() + transaction.transactionType.slice(1),
                    transactionSubType: transaction.transactionSubType,
                    exchangeRate: transaction.exchangeRate,
                };
            });

            this.transactions = transactionsArray;
            this.totalTransactions = transactions.total;
            this.loadingOverviewTableData = false;

            if (changeStore) {

                 this.$store.commit('SET_TRANSACTION_DATA', {
                        totalTransactions: this.totalTransactions,
                        transactions: this.transactions,
                    }
                );
            }
        },

        loadMoreTransactions(event) {
            if( this.selectedAccountData ) {
                this.loadingOverviewTableData = true;
                this.currentOffset = event.first;
                this.getTransactionsData(this.selectedAccountData.investmentAccountId, this.limitPerPage, this.currentOffset, false);
            }
        },

        getOrdersData(accountId, limit, offset, pendingOnly, changeStore = true) {     
            TradingService.getOrders(accountId, limit, offset, pendingOnly).then(resp => {
                this.orders = [];
                if (resp.data.status === 'success') {
                    this.orderTotal = null;
                    this.orders = [];

                    if (resp.data.status === 'error') {
                        this.loadingOverviewTableData = false;
                        //this.$toast.add({ severity: 'error', summary: 'Error getting transaction data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });

                        this.$store.commit('SET_ORDERS_DATA', {
                                orderTotal: this.orderTotal,
                                orders: this.transactions,
                            }
                        );
                    }

                    this.orders = getDataFromOrders(resp.data.tradeOrders);
                    this.orderTotal = resp.data.total;
                    this.loadingOverviewTableData = false;

                    if (changeStore) {

                        this.$store.commit('SET_ORDERS_DATA', {
                                orderTotal: this.orderTotal,
                                orders: this.orders,
                            }
                        );
                    }
                }

                this.ordersLoaded = true;
            })
        },

        loadMoreOrdersCallback(event) {
            this.loadingOverviewTableData = true;
            this.currentOffset = event.first;
            this.getOrdersData(this.selectedAccountData.investmentAccountId, this.limitPerPage, this.currentOffset, false, false);
        },


        cancelledOrderCallback() {
            this.loadingOverviewTableData = true;
            this.currentOffset = 0;
            this.getOrdersData(this.selectedAccountData.investmentAccountId, this.limitPerPage, this.currentOffset, false, false);
        },

        openPositionInputModal(data = null) {

            let inputData = {};

            inputData['account'] = this.selectedAccountData;
            inputData['fromGroup'] = this.selectedGroup;
            inputData['fromUserProfile'] = false;
            inputData['security'] = data?.security;
            inputData['quantity'] = data?.quantity;

            this.$refs.positionInputModal.open(inputData);
        },

        onEnterPositionClick() {
            this.openPositionInputModal({
                account: this.selectedAccountData,
                fromGroup: this.selectedGroup ? true : false
            });
        },

        onEnterCashTxnClick() {
            this.$refs.cashTransactionModal.open({
                account: this.selectedAccountData,
                fromGroup: this.selectedGroup ? true : false
            });
        }

    },
};
</script>

<style scoped>

.reset-confirm-container {
    font-size:16px;
    /* padding: 15px; */
    margin:10px;
}

::v-deep(.perf-currency-button > * > .p-button) {
    font-size:9px !important;
    padding: 4px 6px 4px 6px;
}
::v-deep(.perf-currency-button > * > .p-button:focus) {
   box-shadow: none;
}
::v-deep(.perf-currency-button-mobile ) {
    margin-left: 12px;
    margin-top: 6px;
}
::v-deep(.perf-currency-button-mobile > * > .p-button) {
    font-size:9px !important;
    padding: 4px 6px 4px 6px;
}
::v-deep(.perf-currency-button-mobile > * > .p-button:focus) {
   box-shadow: none;
}



.overview-table {
    width: 100%;
    margin-bottom: 48px;
}

.grid-header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}
.grid-header-text {
    margin-right: 8px;
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    flex: 1 0 auto;
}
.account-details-buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 0 1 auto;
}
.table-header {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    margin-bottom: 0;
    margin-right: 4px;
}

.table-header-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
}

.selected-account-card {
    width: 100%;
}
.selected-account-card .p-grid {
    padding: 0px 16px;
}
.selected-account-card .p-grid > .left-col {
    padding: 0px 72px 0px 0px;
}
.selected-account-card .p-grid > .right-col {
    padding: 0px 0px 0px 72px;
}
.selected-account-card table {
    width: 100%;
}
.selected-account-card table .left-col {
    text-align: left;
}
.selected-account-card table .right-col {
    color: black;
    text-align: right;
}

.paper-analysis-trade-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* margin-top: 24px; */
    
    margin-left: 10px;
}
.paper-analysis-trade-buttons > .p-button {
    border-radius: 50px;
}
.enter-position-button {
    /* margin-right: 16px; */
    font-size: 12px;
    
}

.cash-position-button {
     font-size: 12px;
     margin-top: 5px;
}

.reset-button {
    color: #32364e;
    background: #FFFFFF;
    border-color: red;
}

.reset-button:enabled:hover {
    color: #FFFFFF;
    background: red;
    border-color: red;
}

.enter-trade-button > img {
    width: 24px;
    
}

.holdings-wrapper,
.orders-wrapper,
.transactions-wrapper {
    display: flex;
    align-items: center;
}



.actions-container {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.pending-orders-checkbox-container {
    display: flex;
    align-items: center;
    margin-right: 16px;
}
.p-checkbox {
    margin-left: 8px;
}

.transfer-funds-button {
    padding: 6px 12px;
}
.transfer-funds-button img {
    width: 20px;
    height: 24px;
    margin-right: 4px;
}

.buy-playdough-button {
    padding: 6px 12px;
}
.buy-playdough-button img {
    width: 30px;
    height: 24px;
}

::v-deep(.p-checkbox):not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #33CC99;
}
::v-deep(.p-checkbox):not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #00C083;;
    background: #00C083;
}
::v-deep(.p-checkbox):not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: #00C083;
    box-shadow: none;
}

::v-deep(.overview-table-toggle .p-highlight),
::v-deep(.overview-table-toggle .p-button:hover),
::v-deep(.overview-table-toggle .p-button:focus) {
    background: #32364e;
    border-color: #32364e;
    color: #ffffff;
    box-shadow: none;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button.p-highlight) {
    background: #32364e;
    color: #ffffff;
    pointer-events: none;
    z-index: 2;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button) {
    border-color: #32364e;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button:first-of-type) {
    border-radius: 2rem 0 0 2rem;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button:last-of-type) {
    border-radius: 0 2rem 2rem 0;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button:not(.p-highlight):hover) {
    background: #32364e;
    border-color: #32364e;
    color: #ffffff;
}

::v-deep(.overview-table-toggle .p-selectbutton .p-button-label) {
    padding: 0.1rem 0.5rem;
}

::v-deep(.overview-table-toggle span) {
    font-size: 12px;
}


::v-deep(.holdings-view-toggle .p-highlight),
::v-deep(.holdings-view-toggle .p-button:hover),
::v-deep(.holdings-view-toggle .p-button:focus) {
    background: #32364e;
    border-color: #32364e;
    color: #ffffff;
    box-shadow: none;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button.p-highlight) {
    background: #32364e;
    color: #ffffff;
    pointer-events: none;
    border-radius: 2rem;
    z-index: 2;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button) {
    border-color: #32364e;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button:first-of-type) {
    left: 13px;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
    border-radius: 2rem 0 0 2rem;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
    border-radius: 0 2rem 2rem 0;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):hover) {
    background: #32364e;
    border-color: #32364e;
    color: #ffffff;
}

::v-deep(.holdings-view-toggle .p-selectbutton .p-button-label) {
    padding: 0.1rem 0.5rem;
}

::v-deep(.holdings-view-toggle span) {
    font-size: 12px;
}

::v-deep(*:not(.pi, .material-icons-outlined,.material-symbols-outlined)), ::v-deep(.p-component){
    font-family: 'Trebuchet MS', 'Verdana';
}



@media (max-width: 760px) {
    /* .selected-account-card .p-grid div {
        width: 50%;
    } */
    .selected-account-card .p-grid > .left-col {
        padding: 0px 16px 0px 0px;
    }
    .selected-account-card .p-grid > .right-col {
        padding: 0px 0px 0px 16px;
    }

    .grid-header {
        flex-direction: column;
    }

    .grid-header-text {
        margin: 0 auto 12px 0;
    }

    /* .account-details-buttons-container {
        justify-content: flex-end;
    } */

    .transfer-funds-button {
        padding: 3px 9px;
        font-size: 10px;
        margin-right: 4px;
    }

    .buy-playdough-button {
        padding: 3px 9px;
        font-size: 10px;
    }

    .holdings-wrapper {
        width: 100%;
    }
    .paper-analysis-trade-buttons {
        
        
        margin-left: auto;
    }
    .paper-analysis-trade-buttons button {
        font-size: 10px;
    }
    .enter-position-button {
        
        padding: 3px 7px;
    }

    .cash-position-button {
        padding: 6px 14px;
    }
    .enter-position-button >span.material-symbols-outlined {
       
        font-size:18px;
    }
    

    .enter-trade-button {
       
        padding: 3px 7px;
    }
    .enter-trade-button > img {
       
        width:18px;
    }
  
    ::v-deep(.overview-table-toggle) {
        margin-left: auto;
    }
    ::v-deep(.holdings-view-toggle .p-selectbutton > div) {
        padding: 0.1rem 0.2rem;
    }

    ::v-deep(.holdings-view-toggle .p-highlight),
    ::v-deep(.holdings-view-toggle .p-button:hover),
    ::v-deep(.holdings-view-toggle .p-button:focus) ,
    ::v-deep(.holdings-view-toggle .p-button){
        padding: 5px;
    }

    ::v-deep(.holdings-view-toggle.p-selectbutton .p-button) {
        padding-left: 15px;
        padding-right: 15px;
    }

  ::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
        padding-left: 8px;
    }

    ::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
        padding-right: 8px;
    }

    ::v-deep(.holdings-view-toggle span) {
        font-size: 10px;
    }



    ::v-deep(.overview-table-toggle .p-selectbutton > div) {
        padding: 0.1rem 0.2rem;
    }

    ::v-deep(.overview-table-toggle .p-highlight),
    ::v-deep(.overview-table-toggle .p-button:hover),
    ::v-deep(.overview-table-toggle .p-button:focus) ,
    ::v-deep(.overview-table-toggle .p-button){
        padding: 5px;
    }

    ::v-deep(.overview-table-toggle.p-selectbutton .p-button) {
        padding-left: 15px;
        padding-right: 15px;
    }

    ::v-deep(.overview-table-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
            padding-left: 8px;
        }

        ::v-deep(.overview-table-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
            padding-right: 8px;
        }

        ::v-deep(.overview-table-toggle span) {
            font-size: 10px;
        }
        
       
    
    }
     ::v-deep(.toggle-disabled) {
            opacity: 0.3;
            pointer-events: none;
        }    
</style>
