<template>
    <DataTable v-if="userHasOrders" id='ordersTable' :value="orders" :scrollable="true" scrollHeight="500px" :rowClass="portfolioRowClass" :resizableColumns="true" columnResizeMode="expand"
    :paginator="true" :rows="limitPerPage" :totalRecords="orderTotal" :lazy="true" :loading="loadingOrders" @page="onPage" @sort="onSort">
        <Column  v-if="!competition" :header="actionsHeader" headerClass="orders-header" class="action-column" :frozen="true">
          <template #body="slotProps"> 
            <Button class='red-button__secondary cancel-button' icon='pi pi-times' @click='onCancelOrderClick(slotProps.data)' v-if="slotProps.data.status === 'Pending' && afterEffectiveDate(slotProps.data)"/>
          </template>
        </Column>
        <Column v-if="(competition != null) && isGroupOwnerAdminOrMod" header="Name" class="username-column">
            <template #body="slotProps">
                <span class="username-label" @click='onUserClicked(slotProps.data)'
                    v-tooltip.bottom="'@' + getAnalystName(slotProps.data?.analyst)">
                    @{{ getAnalystName(slotProps.data?.analyst) }}
                </span>
            </template>
        </Column>
        <Column  v-if="!competition || isGroupOwnerAdminOrMod" field="tradeOrderId" header="Order #" headerClass="orders-header" class="order-num-column"/>
        <Column field="datePlaced" :header="datePlacedHeader" headerClass="orders-header" class="date-placed-column" :sortable="(competition != null)"/>
        <Column field="tradeType" :header="tradeTypeHeader" headerClass="orders-header" class="trade-type-column" >
          <template #body="slotProps"> 
            <div :class="valueClass(slotProps.data, 'tradeType')">
              {{slotProps.data.tradeType}}
            </div>
          </template>
        </Column>
        <Column field="symbol" :header="symbolHeader" headerClass="orders-header" class="symbol-column" :sortable="(competition != null)">
          <template #body="slotProps"> 
            <span @click="goToOverview(slotProps.data)">
              {{slotProps.data.symbol}}
            </span>
          </template>
        </Column>
        <Column field="orderType" :header="orderTypeHeader" headerClass="orders-header" class="order-type-column" />
         <Column field="status" :header="statusHeader" headerClass="orders-header" class="status-column" />
          <Column field="effectiveDate" header="Est. Fill Date" headerClass="orders-header" class="status-date-column" v-if="showDelay"/>
         <Column field="executionDate" header="Date Filled" headerClass="orders-header" class="status-date-column" :sortable="(competition != null)" />
         
        
        <Column field="description" :header="descriptionHeader" headerClass="orders-header" class="description-column" />
       
        <Column field="price" :header="priceHeader" headerClass="orders-header" class="price-column">
        <template #body="slotProps">
          {{slotProps.data.price ? valueFormatter(slotProps.data.price, 'currency' ): '-'}}
        </template>
      </Column>
      <Column v-if="!competition || isGroupOwnerAdminOrMod" field="fee" header="Fee" headerClass="orders-header" class="fee-column">
        <template #body="slotProps">
          <div class="negative" v-if="slotProps.data.fee">
            {{valueFormatter(slotProps.data.fee, 'currency') }}
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Column>
      <Column field="quantity" :header="quantityHeader" headerClass="orders-header" class="quantity-column" :sortable="(competition != null)" />
      <Column v-if="!competition || isGroupOwnerAdminOrMod" field="grossAmount" header="Sub-Total" headerClass="orders-header" class="sub-total-column" >
        <template #body="slotProps">
          {{slotProps.data.grossAmount ? valueFormatter(slotProps.data.grossAmount, 'currency') : '-'}}
        </template>
      </Column>
      <Column v-if="!competition || isGroupOwnerAdminOrMod" field="exchangeRate" header="Exchange Rate" headerClass="orders-header" class="exchange-rate-column" >
        <template #body="slotProps">
          {{slotProps.data.exchangeRate ? slotProps.data.exchangeRate : '-'}}
        </template>
      </Column>
      <Column field="netAmount" :header="totalCostHeader" headerClass="orders-header" class="total-cost-column" :hidden="pendingOrdersOnly" :sortable="(competition != null)">
        <template #body="slotProps">
          {{slotProps.data.netAmount ? valueFormatter(slotProps.data.netAmount, 'currency') : '-'}}
        </template>
      </Column>
      <Column v-if="!competition || isGroupOwnerAdminOrMod" field="duration" :header="durationHeader" headerClass="orders-header" class="duration-column" />
      <Column field="stopPrice" :header="stopPriceHeader" headerClass="orders-header" class="stop-price-column" >
        <template #body="slotProps">
          {{slotProps.data.stopPrice ? valueFormatter(slotProps.data.stopPrice, 'currency') : '-'}}
        </template>
      </Column>
      <Column field="limitPrice" :header="limitPriceHeader" headerClass="orders-header" class="limit-price-column" >
        <template #body="slotProps">
          {{slotProps.data.limitPrice ? valueFormatter(slotProps.data.limitPrice, 'currency') : '-'}}
        </template>
      </Column>
      <Column field="cancelledDate" header="Date Cancelled" headerClass="orders-header" class="status-date-column" />
         <Column field="expiryDate" header="Expiry Date" headerClass="orders-header" class="status-date-column" />
      <Column v-if="!competition || isGroupOwnerAdminOrMod" field="statusNote" :header="statusNoteHeader" headerClass="orders-header" class="status-note-column" >
        <template #body="slotProps">
          {{slotProps.data.statusNote ? slotProps.data.statusNote : '-'}}
        </template>
      </Column>
      
      
    </DataTable>
    <p class="no-orders-text" v-else>We were unable to load any orders for this account.</p>

    <ConfirmModal  headerText='Cancel this order?' confirmButtonLabel='OK'  ref='confirmCancelModal' @confirmed="cancelOrderConfirmed" @cancelled="cancelOrderCancelled"/>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import ConfirmModal from '../../modal/ConfirmModal.vue';

import ResearchService from '../../../service/ResearchService';
import TradingService from '../../../service/TradingService';
import BrowserUtils from '../../../utilities/BrowserUtils';
import FormattingUtils from '../../../utilities/FormattingUtils';
import { BUY_TRADE_TYPE, SELL_TRADE_TYPE } from '../../../common/constants';
import TimeUtils from '../../../utilities/TimeUtils';
import UserUtils from '../../../utilities/UserUtils';
export default {
  name: 'OrdersTable',
  emits: ['cancelled-order', 'load-more-orders', 'user-clicked', 'on-sort'],
  props: {
    orders: {
      required: true,
    },

    loadingOrders: {
      required: true
    },

    orderTotal: {
      required: true
    },

    limitPerPage: {
      required: true
    },

    pendingOrdersOnly: {
      required: true
    },

    competition: {
      required: false,
    },

    showDelay: {
      type: Boolean,
      required: false,
      default: false,
    }

  },
  components: {
    DataTable,
    Column,
    ConfirmModal
  },

  computed: {
    userHasOrders() {
      return this.orders.length > 0;
    },

    isMobile() {
      return BrowserUtils.isMobile();
    },

    isGroupOwnerAdminOrMod() {
      if( this.competition ){
       
        return UserUtils.isGroupOwnerAdminOrMod(this.competition.hostingGroup.groupOwnerId, this.$store.getters['users/groupRoleList']);
      }
      else {
        return false;
      }
    }
    


  },
  watch: {
    // holdings() {
    //   console.log("holdings changed");
    // },

    holdings: {
        handler: function() {
              //console.log("holdings changed");
        },
        deep: true
    },

    holdingsView(val) {
      
        //console.log("holdingsView changed to "+val);
        if(val == 'Trading') {
          this.viewTrades = true;


        }
        else {
          this.viewTrades = false;
        }
      }
    },

    data() {
      return {
        formatter: new FormattingUtils(),

        datePlacedHeader: 'Date Placed',
        tradeTypeHeader: 'Buy/Sell',
        descriptionHeader: 'Description',
        statusHeader: 'Status',
        symbolHeader: 'Security',
        orderTypeHeader: 'Order Type',
        durationHeader: 'Duration',
        priceHeader: 'Price',
        stopPriceHeader: 'Stop Price',
        limitPriceHeader: 'Limit Price',
        quantityHeader: 'Quantity',
        totalCostHeader: 'Total Amount',
        statusNoteHeader: 'Status Note',
        statusDateHeader: 'Status Date',
        actionsHeader: 'Actions',
        exchangeSymbolHeader: 'Exchange Symbol',
        orderToBeCancelled: null
      }
    },

  created() {
    // console.log(this.holdings);
  }, 
  mounted() {
    //console.log("competiton:", this.competition)
  },

  methods: {

    onSort(event){
      this.$emit('on-sort', event);
    },

    afterEffectiveDate(order){
      //console.log("order is", order);
      return TimeUtils.isServerTimeSameOrBeforeNow(order.effectiveDate);
    },

    analystDeleted(analyst) {
            
        return analyst.name.endsWith("-"+analyst.analystId);
    },

    getAnalystName(analyst) {
        if( !analyst) {
          return "";
        }
        else if( this.analystDeleted(analyst)){
              return "xxxxxx";
        }
        else {
            return analyst.name;
        }
    },

    onPage(event) {
      this.$emit('load-more-orders', event);
    },

    onCancelOrderClick(order) {
      this.orderToBeCancelled = order;
      this.$refs.confirmCancelModal.open();
    },

    cancelOrderCancelled() {
      this.orderToBeCancelled = null;
    },

    cancelOrderConfirmed() {
      TradingService.cancelOrder(this.orderToBeCancelled.tradeOrderId).then(resp => {
          this.orderToBeCancelled = null;
          if (resp.data.status === 'success') {
            this.$toast.add({ severity: 'success', summary: 'Successfully cancelled order!', life: 2500, group: 'center'});
            this.$emit('cancelled-order');
          } else {
            this.$toast.add({ severity: 'error', summary: 'Failed to cancel order.', life: 2500, group: 'center'});
          }
      })
    },

    onUserClicked(item) {
      if (!this.analystDeleted(item.analyst)) {
        this.$emit('user-clicked', item);
      }
        
    },

    goToOverview( slotProps ) {
      let router = this.$router;
      let symbol = null;
      let exSymbol = null;
      if( slotProps.otherAsset ) {
        symbol =  slotProps.otherAsset?.symbol ;
        exSymbol = slotProps.otherAsset?.exchangeSymbol;
      ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
              if (resp == 'success') {
                router.push({ path: '/equity' });
              }
          });
      }
      else if( slotProps.securityListing ) {
        symbol =  slotProps.securityListing?.symbol 
         exSymbol = slotProps.securityListing?.exchangeSymbol;

          ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
              if (resp == 'success') {
                router.push({ path: '/equity' });
              }
          });
        
      } else {
        symbol =  slotProps.symbol 
         exSymbol = slotProps.exchangeSymbol;

          ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
              if (resp == 'success') {
                router.push({ path: '/equity' });
              }
          });
      }

    },
    
    valueClass(data, type) {
      if (type === 'tradeType') {
        return [
          {
            'buy': data.tradeType === BUY_TRADE_TYPE['NAME'],
            'sell': data.tradeType === SELL_TRADE_TYPE['NAME'],
          }
        ];
      }
    }, 

    portfolioRowClass() {
      return 'portfolio-row';
    },

    valueFormatter(value, type) {
      if (type === 'currency') {
        return this.formatter.format(value, '$0.00');
      }
    }
  },
}
</script>

<style scoped>
::v-deep(*:not(.pi, .material-icons-outlined, .material-symbols-outlined)), ::v-deep(.p-component){
    font-family: 'Trebuchet MS', 'Verdana';
}



.no-orders-text {
  font-family: "Trebuchet MS";
  font-size: 12px;
  color: #999;
}

::v-deep(.portfolio-row) {
  font-family: 'Trebuchet MS';
}

::v-deep(.orders-header), ::v-deep(.p-column-title) {
  font-family: 'Trebuchet MS';
  font-weight: 400;
  font-size: 12px;
  background: #fff !important;
  color: #999;
}

.p-datatable ::v-deep(.p-datatable-thead > tr > th) {
  background: #fff;
}

.p-datatable ::v-deep(.p-datatable-tbody > tr > td) {
  font-size: 12px;
  color: black;
}

::v-deep(.username-column){
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.username-label) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

::v-deep(.username-label:hover) {
    color: #33CC99;
    cursor: pointer;
}

::v-deep(.description-column){
  min-width: 550px;
  max-width: 550px;
}

::v-deep(.duration-column) {
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.status-column) {
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.order-type-column){
  min-width: 110px;
  max-width: 110px;
}

::v-deep(.order-num-column){
  min-width: 60px;
  max-width: 60px;
}


::v-deep(.date-placed-column){
  min-width: 175px;
  max-width: 175px;
}

::v-deep(.status-date-column){
  min-width: 175px;
  max-width: 175px;
}

::v-deep(.fee-column) {
  min-width: 100px;
  max-width: 100px;
}

::v-deep(.status-note-column) {
  min-width: 200px;
  max-width: 200px;
}

::v-deep(.symbol-column) {
  min-width: 90px;
  max-width: 90px;
   /* flex: none !important; */
}
::v-deep(.symbol-column span:hover) {
  color: #33CC99;
  cursor: pointer;
   /* flex: none !important; */
}

::v-deep(.price-column) {
  min-width: 125px;
  max-width: 125px;
}
::v-deep(.trade-type-column) {
  min-width: 100px;
  max-width: 100px;
}


::v-deep(.quantity-column) {
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.stop-price-column) {
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.limit-price-column) {
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.sub-total-column) {
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.exchange-rate-column) {
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.total-cost-column) {
  min-width: 125px;
  max-width: 125px;
}

::v-deep(.cancel-button) {
  border-radius: 50px;
  justify-content: center;
}

.buy {
  color: #33CC99;
  font-weight: bold;
}

.negative,
.sell {
  color: #e63e3e;
  font-weight: bold;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
    color: #32364e;
}
::v-deep(.p-paginator-first:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-next:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-last:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover) {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
::v-deep(.p-paginator-first:focus),
::v-deep(.p-paginator-prev:focus),
::v-deep(.p-paginator-next:focus),
::v-deep(.p-paginator-last:focus),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus) {
    box-shadow: none;
}


 @media (max-width: 760px) {
    ::v-deep(.symbol-column) {
      min-width: 98px;
      max-width: 98px;
      flex: none !important;
      
    }

    ::v-deep(.view-item-button) {
        
      background: #FFFFFF;
      border: 1px solid #33CC99;
      box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
      border-radius: 8px;
      color: #32364e;
      width: 100px;
  }
} 
</style>
