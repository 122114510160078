import { BUY_TRADE_TYPE,
    MARKET_ORDER_TYPE_ID, LIMIT_ORDER_TYPE_ID, STOP_LOSS_ORDER_TYPE_ID, STOP_ENTRY_ORDER_TYPE_ID, STOP_LIMIT_ORDER_TYPE_ID,
    MARKET_CLOSE_DURATION_ID, UNTIL_CANCELLED_DURATION_ID, FILL_OR_KILL_DURATION_ID } from '../../common/constants';
import TimeUtils from '../../utilities/TimeUtils';

export function getTotalCash(cashPositions) {
    return cashPositions.reduce((total, position) => total + position.marketValue, 0);
}

export function getDataFromOrders(data) {
    const ordersArray = data.map((order) => {             
        const ORDER_TYPE_MAP = {
            [MARKET_ORDER_TYPE_ID]: 'Market',
            [LIMIT_ORDER_TYPE_ID]: 'Limit',
            [STOP_LOSS_ORDER_TYPE_ID]: 'Stop Loss',
            [STOP_ENTRY_ORDER_TYPE_ID]: 'Stop Entry',
            [STOP_LIMIT_ORDER_TYPE_ID]: 'Stop/Limit'
        };
        
        const DURATION_TYPE_MAP = {
            [MARKET_CLOSE_DURATION_ID]: 'Day',
            [UNTIL_CANCELLED_DURATION_ID]: '30 Days',
            [FILL_OR_KILL_DURATION_ID]: 'Immediate'
        };

        const dateFormat = "YYYY/MM/DD HH:mm";
        const isPending = order.executionDate === undefined;
        let delayedTrade = (!order.executionDate && order.createdDate == order.lastChecked && order.tradingDelay > 0);
       // let statusDate = null;
        let status = null;
        if (order.cancelledDate !== undefined) {
            //statusDate = TimeUtils.getFormattedTime(order.cancelledDate, dateFormat)
            status = 'Cancelled';
        } else {
            if (isPending) {
                if (TimeUtils.isServerTimeSameOrBeforeNow(order.expiryDate, dateFormat)) {
                    //statusDate = TimeUtils.getFormattedTime(order.expiryDate, dateFormat);
                    status = 'Expired';
                } else {
                    //statusDate = TimeUtils.getFormattedTime(order.effectiveDate, dateFormat)
                    
                    status = 'Pending';
                }
            } else {
               // statusDate = TimeUtils.getFormattedTime(order.executionDate, dateFormat)
                status = 'Filled';
            }
        }

        return {
            analyst: order?.analyst,
            tradeOrderId: order.tradeOrderId,
            datePlaced: TimeUtils.getFormattedTime(order.createdDate, dateFormat),
            tradeType: order.tradeTypeId === BUY_TRADE_TYPE['ID'] ? 'Buy' : 'Sell',
            status: status,
            orderType: ORDER_TYPE_MAP[order.orderTypeId],
            description: order.description,
            symbol: order.symbol,
            exchangeSymbol: order.exchangeSymbol,
            duration: DURATION_TYPE_MAP[order.orderDurationId],
            exchangeRate: order.exchangeRate,
            fee: order.fee,
            grossAmount: order.grossAmount,
            price: order.executedPrice !== undefined ? order.executedPrice : null,
            stopPrice: order.stopPrice !== undefined ? order.stopPrice: null,
            limitPrice: order.limitPrice !== undefined ? order.limitPrice : null,
            statusNote: order.statusNote !== undefined ? order.statusNote : null,
            quantity: order.quantity,
            netAmount:  isPending ? null : order.netAmount,
            executionDate: (order.executionDate ? TimeUtils.getFormattedTime(order.executionDate, dateFormat):''),
            cancelledDate: (order.cancelledDate ? TimeUtils.getFormattedTime(order.cancelledDate,dateFormat) : ''),
            expiryDate: (order.expiryDate ? TimeUtils.getFormattedTime(order.expiryDate, dateFormat):''),
            effectiveDate: (order.effectiveDate? TimeUtils.getFormattedTime(order.effectiveDate,dateFormat):''),
            
            delayedTrade: delayedTrade
        };
    });

    return ordersArray;
}