<template>
  <div class="card performance-card">
    <div id='performance-chart'>
      <div class="header-container">
        <div class="title-legend-container">
          <div class="chart-header-container">
            <h2 class="chart-header" v-if="!isCryptoAccount">Performance</h2>
            <h2 class="chart-header" v-else>Total Value</h2>
            <i class="pi pi-info-circle" v-tooltip.right="'The performance chart shows your account performance versus the '+comparisonIndex+'. Values may be slightly different due to foreign exchange.'" v-if='!isCryptoAccount && showInfoIcon'></i>
          </div>
          <div class="legend" v-if="!isCryptoAccount">
            <div class="portfolio-legend">  
              <div></div>
              <p class="legend-text">Portfolio</p>
            </div>
            <div class="comparison-legend">
              <div></div>
              <p class="legend-text">{{comparisonIndex}}</p>
            </div>
          </div>
        </div>
        <div class="top-right-container">
          <div :class="{'portfolio-return-container': totalReturnAmount || totalMarketValue, 'portfolio-return-percent-container': !totalReturnAmount && !totalMarketValue }" >
            <template v-if="(totalReturnAmount || totalMarketValue) || isCryptoAccount">
              <p class="portfolio-value">{{valueFormatter(totalMarketValue, 'currency')}}</p>
              <p class="portfolio-returns" :class="{positive: isPositive(returnAmount), negative: !isPositive(returnAmount)}" v-if='!isCryptoAccount'>{{valueFormatter(returnAmount, 'currency')}}<span> / </span>{{valueFormatter(returnPercent, 'percentage')}}</p>
            </template>
            <template v-else>
              <p :class="{'portfolio-return-percent': true, positive: isPositive(returnPercent), negative: !isPositive(returnPercent)}">{{valueFormatter(returnPercent, 'percentage')}}</p>
            </template>
          </div>
          <template v-if="viewingOwnPortfolio">
            <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
            <Menu ref="menu" :model="moreMenuItems" :popup="true" />
          </template>
        </div>
      </div>
      <div class="chart-container" v-if='!isCryptoAccount'>
        <Chart type="line" :data="chartData" :options="chartOptions" v-if="multipleReturnDataPoints"/>
        <p v-else class="insufficient-return-data">We were unable to load any performance data for this account.</p>
      </div>
    </div>
  </div>

  <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" v-if="viewingOwnPortfolio"/>
</template>

<script>

import SnapshotDialog from '../../modal/SnapshotDialog.vue';
import MoreOptionsMenu from '../../menu/MoreOptionsMenu.vue';

import Chart from 'primevue/chart';

export default {
  name: 'PerformanceChart',
  components: {
    Chart, SnapshotDialog, MoreOptionsMenu
  },
  props: {
    totalReturnAmount:{
      default: null,
    },
    totalMarketValue: {
      default: null,
    },
    portfolioReturns: {
      required: true
    },
    returnDates: {
      required: true
    },
    comparisonReturns: {
      required: true
    },
    isCryptoAccount: {
      required: true
    },
    showInfoIcon: {
      default: true
    },
    viewingOwnPortfolio: {
      type: Boolean,
      required: true
    },
    selectedAccount: {
      required: true
    },
    comparisonIndex: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      allocationChart: null,
      chartData: {
        labels: this.returnDates,
        datasets: [
          {
            label: 'Portfolio',
            data: this.portfolioReturns,
            borderColor: '#3C9',
            tension: 0.2,
            pointBackgroundColor: '#3C9',
            pointBorderColor: '#3C9',
            pointHoverRadius: 5
          },
          {
            label: this.comparisonIndex,
            data: this.comparisonReturns,
            borderJoinStyle: 'none',
            borderColor: '#bfbfbf',
            tension: 0.2,
            pointBackgroundColor: '#bfbfbf',
            pointBorderColor: '#bfbfbf',
            pointHoverRadius: 5
          }
        ]
      },
      
      chartOptions: {
        elements: {
            point: {
              radius: 2,
            }
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            font: {
              family: "Trebuchet MS"
            }
          }
        },
        scales: {
          xAxis: {
            grid: {
              display: false,
              drawBorder: false,
            }, 
            ticks: {
              font: {
                family: "Trebuchet MS",
              },
              color: '#999' 
            }
          },
          yAxis: {
            grid: {
              borderDash: [1,5],
              drawBorder: false,
              tickColor: '#fff',
              color: '#BFBFBF',
              font: {
                family: 'Trebuchet MS',
                color: '#999'
              }
            },
            ticks: {
              padding: 8,
              font: {
                family: "Trebuchet MS"
              },
              color: '#999'
            } 
          }
        }
      },

      displaySnapshot: false,
      snapshotTarget: null,

      moreMenuItems: [
        {
            label: 'Take Snapshot',
            icon: 'pi pi-camera',
            command: () => {
              this.openSnapshotDialog();
            }
        }
      ]
    }
  },

  computed: {
    multipleReturnDataPoints() {
      return this.portfolioReturns.length > 1;
    },

    returnPercent() {
      if( this.portfolioReturns.length == 1 && this.selectedAccount && this.selectedAccount.dayReturn) {
        return this.selectedAccount.dayReturn * 100;
      }
      else {
        if( this.portfolioReturns.length > 1) {
          return this.portfolioReturns[this.portfolioReturns.length - 1];
        }
        else {
          return 0;
        }
      }
    },

    returnAmount() {
      if( this.selectedAccount ) {
        if( this.portfolioReturns.length == 1 ) {
          return this.selectedAccount.dayReturnAmount;
        }
        else {
          return this.selectedAccount.cumReturnAmount;
        }
      }
      else {
        return "-";
      }
      //return this.totalMarketValue - (this.totalMarketValue / (1 + (this.returnPercent / 100)));
    }
  },

  methods: {
    isPositive(value) {
      return value >= 0;
    },

    valueFormatter(value, type) {
      if( value != null ) {
        if (type === 'currency') {
          const formattedValue = '$' + value.toLocaleString('en-US', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
          });
          return formattedValue;
        } else if (type === 'percentage') {
          const formattedValue = value.toFixed(2) + '%';
          return formattedValue;
        }
      }
      else {
        return '';
      }
    },

    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },

    openSnapshotDialog() {
      this.snapshotTarget = document.getElementById('performance-chart');
      this.displaySnapshot = true;
    },
  },
}
</script>

<style scoped>
*:not(.pi), ::v-deep(*:not(.pi)) {
  font-family: "Trebuchet MS";
}

::v-deep(.more-options) {
  align-self: flex-start;
}

.chart-header-container {
  display: flex;
  align-items: center;
}

.top-right-container {
  display: flex;
}

.chart-header {
	font-weight: bold;
	color: #32364e;
	font-size: 24px;
	margin-bottom: 4px;
  margin-right: 4px;
}

.insufficient-return-data {
  font-size: 16px;
  color: #999;
  margin-top: 12px;
}

.card {
  margin-bottom: 24px;
}

.header-container {
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.portfolio-return-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 8px;
}

.portfolio-return-percent-container {
  margin-right: auto;
}

.portfolio-value {
  color: #000;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}

.portfolio-returns span {
  color: #000;
  font-weight: bold;
}

.positive {
  color: #3C9;
}

.negative {
  color: #e63e3e;
}

.portfolio-returns {
  font-weight: bold;
}

.portfolio-return-percent {
  font-weight: bold;
  font-size: 24px;
  margin-right: 6px;
}

.legend {
  display: flex;
}

.legend-text {
  color: #999;
  line-height: 1;
}

.portfolio-legend, .comparison-legend {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.portfolio-legend div {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #3C9;
  margin-right: 4px;
}

.comparison-legend div {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #999;
  margin-right: 4px;
}
</style>>